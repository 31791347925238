.dashboard {
    &-payment-convertation-grid {
        width: calc(50% - 16px);
    }

    &-payment-convertation-icon {
        align-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 50px;
    }

    &-payment-section {
        // margin-bottom: 28px;
        background: $white;

        &-right {
            position: absolute;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            right: 0;
            scroll-behavior: smooth;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            margin-top: 3.5rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #0179D2;
            border-radius: 6px;
        }

        @include border-radius(4px);

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;

            &-redesign {
                position: sticky;
                top: 0;
                background-color: rgb(248, 250, 251);
                z-index: 1;
            }

            &-title {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $black;
                margin-bottom: 0;
            }

            &-history {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $black;
                text-decoration: none;
                @include transition(0.5s, linear);

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-categorys {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            position: relative;

            &-alts {
                padding: 0;
                display: flex;
                flex-direction: column;
                list-style: none;
                margin-left: 32px;
                margin-right: 32px;
                margin-top: 0px;
                border-top: 1px solid #E8ECEF;
                width: calc(100% - 64px);

                li {
                    display: flex;
                    flex-direction: row;

                    width: 100%;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    border-bottom: 1px solid #E8ECEF;

                    a,
                    button {
                        text-decoration: none;
                        display: flex;
                        flex-direction: row;
                        align-items: center;


                    }

                    img {
                        width: 40px;
                        height: 40px;
                        object-fit: contain;
                    }

                    p {
                        margin-left: 12px;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: #2E3131;
                        text-align: left
                    }
                }
            }

            &-cards {
                padding: 0;
                margin: 24px 30px;
                list-style: none;
                -ms-box-orient: horizontal;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                // justify-content: space-between;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;

                // gap: 1rem;
                &-active-link {
                    background: #1B75BB !important;

                    img {
                        filter: brightness(10);
                    }
                }

                li {
                    width: 128px;
                    height: auto;
                    // width: calc((100% - 4 * 24px) / 5);
                    // margin-bottom: 24px;
                    // &:not(:nth-child(5n)) {
                    //     margin-right: 24px;
                    // }
                    // margin: 12px;

                    a {
                        width: 100px;
                        height: 100px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;
                        // border: 1px solid #E8ECEF;
                        background: rgba(27, 117, 187, 0.1);
                        // box-shadow: 0px 4px 40px rgba(51, 51, 51, 0.1);
                        border-radius: 8px;

                        p {
                            text-align: center;
                            font-family: 'Roboto';
                            font-size: 14px;
                            line-height: 16px;
                            /* identical to box height, or 114% */

                            text-align: center;

                            color: #2E3131;
                        }
                    }
                }

            }
        }

        &-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 28px 24px;

            &-button {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                width: 100px;
                height: 100px;
                // width: calc((100% - 24px * 7) / 8);
                // margin-bottom: 24px;
                // border: 1px solid #E8ECEF;
                background: rgba(27, 117, 187, 0.1);
                // box-shadow: 0px 4px 40px rgba(51, 51, 51, 0.1);
                border-radius: 8px;
                // &:not(:nth-child(8n)) {
                //     margin-right: 24px;
                // }
            }

            &-icon {
                // margin-top: 16px;
                // height: 52px;
            }

            &-title {
                // font-family: TTInterphases; //temporary
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;
                color: #2E3131;
                margin: 12px 0;
                text-align: center;
            }
        }
    }

    &-payment-operation-card-section {
        display: flex;
        justify-content: space-between;
    }

    &-payment-operation-section {
        padding-bottom: 16px;
        width: calc(50% - 16px);
        background: $white;
        @include border-radius(4px);

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;

            &-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }

            &-history {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $black;
                text-decoration: none;
                @include transition(0.5s, linear);

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-content {
            box-sizing: border-box;
            list-style-type: none;
            padding-inline-start: 0;
            padding: 16px 24px 0;

            &-item {
                text-decoration: none;
                padding: 12px 0;
                display: flex;
                justify-content: space-between;

                &--left-side {
                    display: flex;

                    .dashboard-payment-operation-section-content-item-title__invisible {
                        margin: 8px 0;
                        width: 212px;
                    }

                    .dashboard-payment-operation-section-content-item-subtitle__invisible {
                        margin: 5px 0;
                        width: 140px;
                    }
                }

                &--right-side {
                    .dashboard-payment-operation-section-content-item-title__invisible {
                        margin: 8px 0;
                        width: 60px;
                    }

                    .dashboard-payment-operation-section-content-item-subtitle__invisible {
                        margin: 4px 0;
                        width: 40px;
                    }
                }

                &-icon {
                    height: 20px;

                    &__card {
                        height: 16px;
                    }
                }

                &-icon-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    margin-right: 32px;
                    background: #F0F7FC;
                    @include border-radius(50%);
                }

                &-date-container {
                    margin-right: 52px;
                }

                &-title {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;

                    &__invisible {
                        height: 8px;
                        background: #E8ECEF;
                    }
                }

                &-subtitle {
                    margin: 0;
                    font-size: 12px;
                    line-height: 16px;
                    color: $grey700;

                    &__invisible {
                        height: 6px;
                        background: #E8ECEF;
                    }

                    &__right {
                        text-align: right;
                    }
                }

                &-company {
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                }

                &-purpose {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;
                }

                &-amount {
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                    text-align: right;

                    &-whole-part {
                        font-size: 16px;
                        font-weight: 400;
                    }

                    &-fractional-part {
                        font-size: 14px;
                        font-weight: 300;

                        &::before {
                            content: ".";
                        }
                    }

                    &-currency {
                        font-size: 14px;
                        margin-left: 4px;
                        font-weight: 300;
                    }
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $greyShadow;
                }
            }
        }
    }

    &-payment-card-section {
        padding-bottom: 16px;
        width: calc(50% - 16px);
        background: $white;
        @include border-radius(4px);

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;

            &-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }

            &-history {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $black;
                text-decoration: none;
                @include transition(0.5s, linear);

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-content {
            padding-inline-start: 0;
            padding: 16px 24px 0;

            &-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 64px;
                text-decoration: none;

                &-left-side {
                    display: flex;
                    align-items: center;
                }

                &-right-side {
                    color: $black;
                }

                &-card-icon {
                    margin-right: 16px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;

                    &-image {
                        height: 40px;
                    }
                }

                &-card-name {
                    margin: 0;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                }

                &-card-number {
                    margin: 0;
                    font-size: 12px;
                    line-height: 16px;
                    color: $grey700;
                }

                &-amount-whole-part {
                    font-size: 16px;
                    font-weight: 400;
                }

                &-amount-fractional-part {
                    font-size: 12px;
                    font-weight: 300;

                    &::before {
                        content: ".";
                    }
                }

                &-amount-currency {
                    font-size: 12px;
                    margin-left: 4px;
                    font-weight: 300;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $greyShadow;
                }
            }
        }
    }

    &-payment-container {
        display: flex;
        margin: 28px 32px;
        @include border-radius(4px);
        min-height: calc(100vh - 140px);

        &-data-section {
            width: 70%;
            background: #FFFFFF;

            &--empty {
                width: 100%;
            }

            &-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 56px;
                padding: 0 24px;
                border-bottom: 1px solid $greyShadow;

                &-title {
                    margin: 0;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                }

                &-button {
                    background: #0179D2;
                    border-radius: 2px;
                    padding: 4px 16px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: right;
                    color: #FFFFFF;
                    cursor: pointer;

                    &:disabled {
                        cursor: auto;
                        background: #F3F5F8;
                        color: #586268;
                    }
                }
            }

            &-content {
                &--empty {
                    width: 70%;
                }

                &-container {
                    box-sizing: border-box;
                    padding: 24px 24px 0;

                    &-redesign {
                        &-cashback-info {
                            display: flex;
                            gap: 2rem;

                            div {
                                padding-left: 0;
                            }

                            p {
                                margin: 0;
                                color: #586268;
                                font-size: 13px;
                                line-height: 14px;
                            }
                        }

                    }

                    &-title {
                        margin: 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #2E3131;
                    }

                    &-form-container {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        &-fatcha {
                            button {
                                margin: 1rem;
                                padding: 1rem;
                                background-color: $white;
                                filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.08));
                                background: $white;
                                border-radius: 10px;

                                img {
                                    margin-right: 1rem;
                                }
                            }
                        }
                    }

                    &-form {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 16px 0 24px;

                        &-radioBtns {
                            display: flex;
                            gap: 1.2rem;
                        }

                        &__border-bottom {
                            border-bottom: 1px solid #E8ECEF;
                        }

                        &-button {
                            margin-top: 12px;
                            margin-bottom: 24px;

                            &-title {
                                margin-left: 12px;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 24px;
                                color: #2E3131;
                                @include transition(0.6s, ease);
                            }

                            &-icon path {
                                @include transition(0.6s, ease);
                            }

                            &:hover {
                                .dashboard-payment-container-data-section-content-container-form-button-title {
                                    color: #0179D2;
                                }

                                .dashboard-payment-container-data-section-content-container-form-button-icon path {
                                    fill: #0179D2;
                                }
                            }
                        }

                        &-group {
                            display: flex;
                            align-items: flex-start;

                            &-radio {
                                padding-top: 2px !important;

                                // top: -4px;
                                &__disable {
                                    opacity: .4;
                                }
                            }

                            &:not(:last-child) {
                                margin-right: 68px;
                            }

                            &-icon {
                                display: block;
                                margin-right: 12px;
                            }

                            &-box {
                                // margin-left: 12px;
                            }

                            &-title {
                                margin: 0;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 12px;
                                color: #0179D2;

                                &--light {
                                    // color: #ACB3B8;
                                }
                            }

                            &-data {
                                margin: 0;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 24px;
                                min-height: 24px;
                                color: #2E3131;
                            }
                        }

                        &-input-group {
                            width: 100%;

                            &__grid {
                                width: calc(50% - 16px);
                            }

                            &___grid {
                                width: calc(50% - 150px);
                            }

                            &__fromAnotherCard {
                                width: 343px;
                                background: #1B75BB33;
                                border-radius: 16px;
                                padding: 2rem;
                                display: flex;
                                flex-wrap: wrap;
                                gap: 1rem;

                                h6 {
                                    min-height: 24px;
                                }

                                label {
                                    margin: 0;
                                    color: #757575;
                                    font-size: 15px;
                                }

                                input {
                                    padding: 0;
                                    background-color: transparent;
                                    height: inherit;
                                    font-size: 15px;

                                    &::placeholder {
                                        color: #757575;
                                        display: block !important;
                                    }
                                }

                                .dashboard-payment-container-data-section-content-container-form-input-box {
                                    border: none;
                                }

                                &-group {
                                    display: flex;
                                    gap: 1.6rem;
                                    align-items: end;

                                }
                            }
                            &.check {
                                display: flex;
                                align-items: center;
                                gap: .6rem;
                                margin-top: 1rem;

                                label {
                                    margin: 0;
                                    cursor: pointer;
                                }
                            }
                        }

                        &-input-box {
                            border: 1px solid $greyShadow;
                            position: relative;
                        }

                        &-input {
                            font-size: 12px;
                            width: calc(100% - 50px);
                            border: 0;
                            box-sizing: border-box;
                            padding: 12px 0 12px 16px;
                            height: 48px;
                            @include border-radius(2px);

                            input {
                                font-family: 'Roboto', sans-serif !important;
                            }

                            &:disabled {
                                color: #ACB3B8 !important;
                            }

                            &__whole {
                                width: 100%;
                                padding-right: 16px;
                            }

                            &__disable {
                                color: #ACB3B8 !important;
                            }

                            &__disable-datepicker {
                                background: rgba(239, 239, 239, 0.3) !important;
                                color: #ACB3B8 !important;
                            }

                            &__file {
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 68px;
                                height: 48px;
                                opacity: 0;
                                cursor: auto;
                                overflow: hidden;
                            }

                            .MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
                                &::before {
                                    display: none !important;
                                }

                                &::after {
                                    display: none !important;
                                }
                            }

                            .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                width: 48px;
                                height: 48px;
                                max-height: 48px;
                            }

                            .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
                                width: calc(100% - 48px - 16px);
                                padding: 12px 0 12px 16px;
                                // font-family: TTInterphases; //temporary
                                font-size: 14px;
                                height: 24px !important;
                                color: $black;
                            }

                            .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled {
                                display: none;
                            }

                            button.MuiButtonBase-root.MuiIconButton-root {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0;
                                border-left: 1px solid $grey300 !important;
                                border-radius: 0 !important;
                            }
                        }

                        &-select-box {
                            position: relative;
                            height: 48px;

                            &-button {
                                border: 1px solid $greyShadow;
                                width: 100%;
                                text-align: left;

                                &__disable {
                                    background: rgba(239, 239, 239, 0.3);
                                    color: #ACB3B8 !important;
                                    cursor: auto;

                                    .dashboard-payment-container-data-section-content-container-form-icon-img path {
                                        fill: #ACB3B8 !important;
                                    }
                                }
                            }

                            &-multiple {
                                margin: 0;

                                &-select {
                                    height: 48px;
                                    border-radius: 0 !important;

                                    .MuiSelect-select {
                                        font-size: 12px;
                                    }

                                    fieldset {
                                        border-color: $greyShadow !important;
                                        border-width: 1px !important;

                                        legend {
                                            display: none;
                                        }

                                        // &:hover {
                                        //     border-color: revert;
                                        // }
                                    }

                                    &-isEmpty {
                                        fieldset {
                                            border-color: red !important;
                                        }
                                    }
                                }

                                label {
                                    font-size: 12px;
                                    color: rgb(189, 195, 199);
                                    margin: 0;

                                    // &.MuiFormLabel-filled,
                                    // &.Mui-focused {
                                    //     display: none;
                                    // }
                                }
                            }

                            &-list {
                                // width: calc(100% - 2px);
                                width: 100%;
                                background: #FFFFFF;
                                max-height: 50vh;
                                overflow-y: auto;
                                position: absolute;
                                top: 50px;
                                list-style-type: none;
                                margin: 0;
                                padding-inline-start: 0;
                                border: 1px solid $greyShadow;
                                border-top: 0;
                                z-index: 100;

                                &-item {
                                    padding: 12px 16px;

                                    &-button {
                                        font-size: 14px;
                                        line-height: 24px;
                                        text-align: left;
                                        width: 100%;
                                        color: #2E3131;
                                        @include transition(0.5s, ease);

                                        &:hover {
                                            color: #0179D2;
                                        }

                                        &:disabled {
                                            color: #ACB3B8 !important;
                                            cursor: auto;
                                        }
                                    }
                                }

                                &__hidden {
                                    display: none;
                                }
                            }
                        }

                        &-select {
                            font-size: 12px;
                            height: 48px;
                            line-height: 48px;
                            width: calc(100% - 42px);
                            border: 0;
                            box-sizing: border-box;
                            padding-left: 16px;
                            @include border-radius(2px);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        &-label {
                            min-height: 24px;
                            margin: 16px 0 8px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            color: #252631;
                        }

                        &-checkbox {
                            display: flex;
                            align-items: center;

                            &:not(:last-child) {
                                margin-bottom: 16px;
                            }

                            &__increase-balance-card-btn {
                                font-size: 16px;
                            }

                            &-title {
                                margin-left: 12px;
                            }

                            &-description {
                                margin-top: 8px;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 14px;
                                color: #8E8E8E;

                                &--bold {
                                    margin-left: 4px;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 16px;
                                    color: #2E3131;
                                }
                            }

                            .MuiButtonBase-root {
                                padding: 0;
                            }

                            .MuiFormControlLabel-root {
                                margin: 0;
                            }
                        }

                        &-checkbox-container {
                            display: flex;

                            .dashboard-payment-container-data-section-content-container-form-checkbox {
                                margin: 0;
                            }

                            .dashboard-payment-container-data-section-content-container-form-checkbox-title {
                                // margin-left: 0;
                                margin-right: 20px;
                            }
                        }

                        &-file-container {
                            display: flex;
                            justify-content: space-between;
                            min-height: 50px;
                        }

                        &-file-box {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 10px 16px;
                            width: calc(50% - 6px);
                            border: 1px solid #E4E8EE;
                            box-sizing: border-box;
                            border-radius: 2px;

                            &__disable {
                                background: #F9FAFB;
                            }
                        }

                        &-file-content {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        &-file-title {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 16px;
                            color: #404040;
                        }

                        &-file-description {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 10px;
                            line-height: 12px;
                            color: #8E8E8E;
                        }

                        &-file-button {
                            position: relative;
                            overflow: hidden;
                            width: 24px;
                            height: 24px;
                        }

                        &-file-input {
                            position: absolute;
                            opacity: 0;
                        }

                        &-file-delete {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 3;

                            &:disabled {
                                cursor: auto !important;
                            }
                        }

                        &-icon {
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 48px;
                            height: 48px;

                            &__disable {
                                background: rgba(239, 239, 239, 0.3);
                                color: #ACB3B8 !important;
                                cursor: auto;
                            }

                            &-img {
                                &__phone {
                                    height: 16px;
                                }
                            }

                            &__currency {
                                font-size: 14px;
                                line-height: 24px;
                                border-left: 1px solid #E4E8EE;
                                color: #ACB3B8;
                            }

                            &__choose {
                                background: #0179D2;
                                color: #FFFFFF;
                                padding: 0;
                                width: 68px;
                                text-align: center;

                                &.disabled {
                                    opacity: .5;
                                }
                            }
                        }

                        &-footer {
                            width: 100%;
                            padding: 24px 0;

                            // display: flex;
                            &-button {
                                padding: 12px 24px;
                                margin-bottom: 16px;
                                font-size: 14px;
                                line-height: 24px;
                                text-align: center;
                                @include border-radius(2px);

                                &__blue {
                                    background: #0179D2;
                                    color: #FFFFFF;
                                }

                                &__gray {
                                    background: #F3F5F8;
                                    color: #586268;
                                }

                                &__disable {
                                    cursor: auto;
                                    background: #F3F5F8;
                                    color: #586268;
                                }

                                &__left {
                                    margin-right: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-confirmation-section {
            padding: 0 24px;
            width: 30%;
            background: #F8FAFB;

            &--empty {
                padding: 0;
                background: #FFFFFF;
            }

            &-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 56px;
                border-bottom: 1px solid $greyShadow;

                &-title {
                    margin: 0;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                }
            }

            &-content {
                padding: 32px;

                &-empty {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0 20px;
                }

                &-image {
                    width: 100%;
                }

                &-description {
                    text-align: left !important;
                    margin: 24px 0 0;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #586268;

                    &-title {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }

            &-amount {
                margin-bottom: 32px;
                text-align: center;
                font-size: 48px;
                line-height: 56px;
                color: #2E3131;

                &-whole {
                    font-weight: 500;
                }

                &-fractional {
                    font-size: 36px;
                    font-weight: 300;

                    &::before {
                        content: ".";
                    }
                }

                &-currency {
                    font-weight: 300;

                    // &__azn {
                    //     font-size: 50px;
                    // }
                    &__azn {
                        font-size: 48px;
                        font-weight: 600;
                    }

                    &__azn__new {
                        font-size: 18px;
                        font-weight: 400;
                        color: #586268;
                    }

                    &__usd {
                        font-size: 32px;
                        font-weight: 600;
                    }

                    &__eur {
                        font-size: 36px;
                        font-weight: 600;
                    }

                    &__rub {
                        font-size: 36px;
                        font-weight: 500;
                    }
                }
            }

            &-list {
                margin: 0;
                padding-inline-start: 0;

                &-item {
                    list-style-type: none;
                    padding: 8px 0;

                    &:not(:last-child) {
                        border-bottom: 1px solid #E8ECEF;
                    }
                }
            }
        }
    }

    &-templates-providers-section {
        width: calc(50% - 16px);
        background: $white;
        @include border-radius(4px);

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;

            &-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }
        }

        &-content {
            &-provider {
                display: flex;
                flex-wrap: wrap;
                padding: 28px 24px;

                &-button {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-decoration: none;
                    width: calc((100% - 24px * 3) / 4);
                    margin-bottom: 24px;
                    border: 1px solid #E8ECEF;
                    box-shadow: 0px 4px 40px rgba(51, 51, 51, 0.1);
                    border-radius: 4px;
                    margin-right: 24px;
                }

                &-icon {
                    margin-top: 16px;
                    height: 52px;
                }

                &-title {
                    // font-family: TTInterphases; //temporary
                    font-size: 14px;
                    line-height: 16px;
                    color: #2E3131;
                    margin: 12px 0;
                }
            }
        }
    }
}

.credit-payment-modal {
    width: 40%;
    background-color: #FFFFFF;
    height: max-content;

    label {
        margin-top: 0;
    }

    &-data-section {
        &-header {
            padding: 1rem;
            border-bottom: 1px solid #E8ECEF;

            &-title {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &-content {
            width: 60%;
            margin: auto;
            display: flex;
            gap: 1.5rem;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
            margin-block: 3rem;

            &-schedule {
                color: #0179D2;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
                gap: .6rem;
                display: flex;
                align-items: center;
            }
        }

        &-footer {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }

    }

    @media (max-width: $breakpoint-tablet) {
        width: 90%;
    }

}



.tooltip-image {
    position: fixed;
    top: -17px;
    background-color: #0179D2 !important;
    border: 6px solid #0179D2;
    border-radius: 50%;
}

.tooltip-icon {
    border-left: 1px solid #DADADA;
    margin-left: 8px;
    padding-left: 8px;
    cursor: pointer;
}




$breakpoint-tablet: 768px;
$breakpoint-mobile: 530px;

@media (max-width: $breakpoint-tablet) {
    .dashboard-payment-container-data-section-content--empty {
        width: 100%;
    }

    .dashboard-payment-convertation-grid,
    .dashboard-payment-convertation-icon {
        width: 100%;
    }

    .dashboard-template-section-content-button:not(:nth-child(5n)) {
        margin-right: 0 !important;
    }

    .dashboard-template-section-content-button:not(:nth-child(3n)) {
        margin-right: 24px !important;
    }

    .dashboard-template-section-content {
        &-button {
            width: calc((100% - 24px * 2) / 3) !important;
            flex-direction: column;

            &__left-side {
                margin-right: 0 !important;
                align-items: center;
                justify-content: center;
            }

            &__right-side {
                width: 100% !important;
                text-align: center;
                align-items: center;
                margin-top: 5px;
            }
        }
    }

    .dashboard-payment-section-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &-button {
            // width: calc((100% - (24px * 2)) / 3);

            &:not(:nth-child(8n)) {
                margin-right: 0;
            }

            &:not(:nth-child(3n)) {
                // margin-right: 24px !important;
            }
        }
    }

    .dashboard-payment-operation {
        &-card-section {
            flex-direction: column;
        }

        &-section {
            width: 100%;

            &-content {
                overflow-x: auto;

                &-item {
                    min-width: 460px;
                }
            }
        }
    }

    .dashboard-payment-card-section {
        margin-top: 28px;
        width: 100%;

        &-content {
            overflow-x: auto;

            &-item {
                min-width: 480px;
            }
        }
    }

    .dashboard-transfer-search-form-section {
        flex-direction: column;

        &-item {
            width: 100%;
            margin-right: 0;
        }
    }

    .dashboard-transfer-list {
        &-content {
            overflow-x: auto;
        }

        &-table {
            min-width: 1200px;
        }
    }

    .dashboard {
        &-payment-container {
            flex-direction: column;

            &-data-section {
                width: 100%;

                &-content {
                    &-container {
                        &-form {
                            &-input-group {
                                width: 100%;
                            }

                            &-footer {
                                &-button {
                                    width: calc((100% - 16px) / 2);
                                }
                            }

                            &-container {
                                flex-direction: column;

                                >div:not(.check) {
                                    flex-direction: column !important;
                                }
                            }
                        }
                    }
                }
            }

            &-confirmation-section {
                width: 100%;

                &-content {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                img {
                    max-width: 340px;

                }

                text-align: center;

                p {
                    text-align: center !important;
                }
            }
        }
    }

    .dashboard-payment-section-categorys {
        flex-direction: column;

        &-cards li {
            &:not(:nth-child(5n)) {
                margin-right: 0;
            }

            width: calc((100% - 2 * 24px) / 3);

            &:not(:nth-child(3n)) {
                margin-right: 24px;
            }
        }
    }

    .dashboard-payment-section {
        width: 100% !important;
        position: relative !important;
    }

    .dashboard-payment-container-data-section-content-container-form-container {}
}

@media (max-width: $breakpoint-mobile) {
    .dashboard-payment-container-data-section-header-button {
        padding: 4px;
        font-size: 12px;
        line-height: 20px;
    }

    .dashboard-template-section-content-button {
        width: calc((100% - 24px) / 2) !important;

        &:not(:nth-child(5n)) {
            margin-right: 0 !important;
        }

        &:nth-child(odd) {
            margin-right: 24px !important;
        }
    }

    .dashboard-payment-section-content-button {
        // width: calc((100% - 24px) / 2) !important;
        // width: 100%;

        &:not(:nth-child(3n)) {
            margin-right: 0 !important;
        }

        &:not(:nth-child(8n)) {
            margin-right: 0;
        }

        &:nth-child(odd) {
            // margin-right: 24px !important;
        }
    }

    .dashboard-payment-section-categorys {
        &-cards li {
            &:not(:nth-child(5n)) {
                margin-right: 0;
            }

            &:not(:nth-child(3n)) {
                margin-right: 0;
            }

            width: calc((100% - 24px) / 2);

            &:not(:nth-child(2n)) {
                margin-right: 24px;
            }
        }
    }

    .dashboard-payment-container-data-section-content-container-form-footer-button {
        width: 100%;

        &__left {
            margin-bottom: 16px;
            margin-left: 0;
        }
    }


}

// .MuiOutlinedInput-root:hover {
//     fieldset {
//         border-color: revert !important;
//     }
// }