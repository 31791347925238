$brightBlue: #0066B2;
$softBlue: #0179D2;
$lightBlue: #B6E0FF;
$black: #2E3131;
$grey900: #586268;
$grey800: #849097;
$grey700: #959FA5;
$grey600: #ACB3B8;
$grey500: #BDC3C7;
$grey400: #DEE2E8;
$grey300: #E4E8EE;
$grey200: #E8ECF1;
$grey100: #F3F5F8;
$grey50: #F9FAFB;
$greyShadow:#E8ECEF;
$greyInputShadow: #C4C4C4;
$greyBackground: #E3EAED;
$white: #FFFFFF;
$validationFeedbackColor: #EF3E42;

$containerHorizontalPadding: 152px;
$columnHorizontalMargin: 32px;