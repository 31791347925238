.dashboard {
    &-service-network-branch {
        &-head {
            border-bottom: 1px solid #E8ECEF;
            margin-bottom: 15px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: row;
            &-search {
                display: flex;
                flex-direction: row;
                border-radius: 20px;
                border: 1px solid #E4E8EE;
                height: 40px;
                width: 340px;
                justify-content: space-between;
                align-items: center;
                input {
                    border: 0;
                    width: calc(340px - 40px);
                    background-color: 'none';
                    margin-left: 15px;
                    height: 30px;
                }
                img {
                    margin-right: 15px;
                }
            }
            &-button {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #2E3131;
                background: #FFFFFF;
                height: 40px;
                border-radius: 20px;
                border: 1px solid #E4E8EE;
                padding: 10px 20px;
                margin-left: 20px;
            }
            &-button-active {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #FFFFFF;
                background: #0179D2;
                height: 40px;
                border-radius: 20px;
                padding: 10px 20px;
                margin-left: 20px;
            }
        }
        &-desc {
            display: flex; 
            flex-direction: row;
            margin-bottom: 10px;
            a {
                text-decoration: none;
            }
            &-element {
                width: 50%;
                margin: 0;
                svg {
    
                    font-size: 14px;
                    line-height: 24px;
                    /* identical to box height, or 171% */
                    
                    text-align: center;
                    
                    color: #0179D2;
                }
                span {
                    margin-left: 10px;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    color: #2E3131;
                }
            }
        }
    }
    &-tariff-list {
        width: 100%;
        box-sizing: border-box;
        min-height: calc(100vh - 137px - 64px);
        background: #FFFFFF;
        border-radius: 4px;
        padding: 0;
        list-style-type: none;
        &-item {
            padding: 28px 24px;
            @include transition(0.5s, linear);
            &:not(:last-child) {
                border-bottom: 1px solid #E8ECEF;
            }
            &--clickable:hover {
                background: #F3F5F8;
            }
            &-title {
                margin: 0;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #333333;
                &--bold {
                    color: #000000;
                    margin-bottom: 1.2rem;
                }
            }
            &-description {
                margin: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: #2E3131;
            }
        }
    }
}


$breakpoint-tablet: 768px;
$breakpoint-mobile: 530px;
@media (max-width: $breakpoint-tablet) {
    .map-container {
        width: calc(100vw - 64px);
    }
    .dashboard-service-network-branch-head {
        flex-direction: column;
        > {
            width: 100%;
        }
        &-right {
            margin-top: 12px;
            button {
                margin-left: 0px;
                margin-right: 12px;
            }
        }
    }
    .dashboard-service-network-branch-head-search {
        width: 100%;
    }
}