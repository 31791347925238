.dashboard {
    &-exchange-range-section {
        padding: 0 24px;
        width: calc(70% - 16px);
        background: $white;
        @include border-radius(4px);
        &-background {
            background: #FFFFFF;
        }
        &-table {
            display: table;
            width: 100%;
            padding: 0 24px;
            box-sizing: border-box;
            border-spacing: 0;
        }
        &-row {
            border-bottom: 1px solid #E4E8EE;
        }
        &-cell {
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
            font-size: 14px;
            color: #2E3131;
            padding: 12px 0;
            &-image {
                min-width: 36px;
                margin-right: 12px;
                border-radius: 2px !important;
                width: 36px;
            }
            &__head {
                padding: 16px 0;
                font-size: 12px;
                color: #586268;
                text-align: left;
            }
            &__currency {
                display: flex;
                align-items: center;
            }
        }
    }
    &-currency-convertor-calendar-section {
        width: calc(30% - 16px);
    }
    &-currency-convertor-section {
        background: $greyBackground;
        padding-bottom: 28px;
        &-background {
            background: $white;
            @include border-radius(4px);
        }
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;
            &-title {
                font-size: 16px;
                line-height: 24px;
                color: $black;
                margin-bottom: 0;
            }
        }
        &-content {
            padding: 24px;
            box-sizing: border-box;
        }
    }
    &-currency-convertor-input {
        width: 100%;
        box-sizing: border-box;
        padding: 4px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #2E3131;
        background: #FFFFFF;
        border: 1px solid #E4E8EE;
        box-sizing: border-box;
        border-radius: 100px;
    }
    &-currency-convertor-data {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 24px 0;
        &-currency {
            display: flex;
            align-items: center;
            &-image {
                border-radius: 2px !important;
                width: 36px;
                &-left {
                    min-width: 34px;
                    margin-right: 12px;
                }
                &-right {
                    min-width: 34px;
                    margin-left: 12px;
                }
            }
        }
        &-convert-button {
            margin: 0 24px;
        }
        &-currency-ratio {
            margin-top: 8px;
            text-align: center;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #586268;
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .dashboard {
        &-exchange-range-section {
            width: 100%;
            overflow: auto;
            &-table {
                min-width: 520px;
            }
        }
        &-currency-convertor-calendar-section {
            width: 100%;
            margin-top: 28px;
        }
    }
}