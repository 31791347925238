@mixin border-radius($x) {
    -webkit-border-radius: $x;
       -moz-border-radius: $x;
            border-radius: $x; 
}
@mixin user-select($x) {
    -webkit-user-select: $x;
       -moz-user-select: $x;
        -ms-user-select: $x;
            user-select: $x;
}
@mixin rotate($x) {
    -webkit-transform: rotate($x);
       -moz-transform: rotate($x);
        -ms-transform: rotate($x);
         -o-transform: rotate($x);
            transform: rotate($x);
}
@mixin transformOrigin($x, $y) {
    -webkit-transform-origin: $x $y;
       -moz-transform-origin: $x $y;
        -ms-transform-origin: $x $y;
         -o-transform-origin: $x $y;
            transform-origin: $x $y;
}
@mixin transition($duration, $timingFunction) {
   -webkit-transition: all $duration $timingFunction;
      -moz-transition: all $duration $timingFunction;
        -o-transition: all $duration $timingFunction;
           transition: all $duration $timingFunction;
}