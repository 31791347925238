@import "./../../../../Home/scss/components/variables";

.ibank-digital-card-activation {
  box-sizing: border-box;
  padding: 0px 24px;
  min-height: 100%;
  position: relative;

  &-desc {
    margin: 24px 0px;

    p {
      color: $black;
      line-height: 24px;
      font-weight: 500;
    }
  }

  &-form {
    width: 50%;

    input {
      &:disabled {
        color: $grey600;

      }
    }

    &-actions {
      position: absolute;
      right: 35px;
      bottom: 20px;

      button {
        width: 116px;
      }
    }
  }

  &-rules {
    display: flex;
    align-items: center;
    margin: 30px 0px;

    p {
      margin: 0px;

      span {
        color: $softBlue;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 3px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ibank-digital-card-activation {
    padding: 0px 50px;

    &-form {
      display: inline-block;
      width: 100%;

      &-actions {
        position: static;
        margin-bottom: 10px;

        button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .ibank-digital-card-activation {
    padding: 0px 24px;

    &-form {
      width: 100%;
      display: block;

      input {
        font-size: 14px;
        width: 100%;
        display: block;
        margin: 0px;
      }
    }

    &-rules {
      p {
        font-size: 12px;
      }
    }
  }
}