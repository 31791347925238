@import './../components/variables';

$breakpoint-tablet: 768px;
$breakpoint-mobile: 530px;
.dashboard {
    &-header-route {
        padding: 0 32px;
        margin-top: 28px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: none;
        justify-content: space-between;
        &-button {
            text-decoration: none;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF !important;  
            background: #0179D2;
            border-radius: 2px;
            padding: 10px 30px;
            margin-left: 30px;
        }
        &-left {
            display: flex;
            align-items: center;
            flex-direction: row;
            min-height: 36px;
            h2 {
                margin: 0px 16px 0px 16px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 36px;
                color: #2E3131;
            }
        }
        &-right {
           
        }
        &-link {
            text-decoration: none;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #2E3131;
            text-transform: uppercase;
        }
    }
    &-header-list {
        display: flex;
        align-items: center;
        padding-inline-start: 0;
        list-style-type: none;
        margin: 0;
        min-height: 36px;
        display: flex;
        align-items: center;
        &-item {
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-right: 32px;
            }
        }
        &-link {
            display: flex;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
        }
        &-image {
            margin-right: 12px;
        }
        &-title {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #2E3131;
        }
    }
    &-menu-section {
        width: 20%; //temprary
        // border-right: 1px solid $greyShadow;
        background: $white;
        transition: all 0.3s;
        &__close {
            width: 88px;
            overflow: hidden;
            justify-content: center;
            .dashboard-menu-section-list-item-link-title,
            .dashboard-menu-section-list-item-link-right-section {
                display: none;
            }
            .dashboard-menu-section-list-item-link {
                // display: flex;
                justify-content: center;
            }
            .MuiCollapse-container.MuiCollapse-hidden {
                display: none;
            }
            .dashboard-menu-section-list-item-link,
            .dashboard-menu-section-others-title {
                padding: 0;
                &-icon {
                    margin-right: 0;
                }
            }
            .dashboard-menu-section-others-title {
                text-align: center;
            }
            .dashboard-menu-section-header {
                padding: 0;
                &-logo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 32px;
            height: 72px;
            background: #2C81C1;
            border-bottom: 1px solid $greyShadow;
            &-logo {
                width: 100%;
                display: flex;
                align-items: center;
                &-icon {
                    display: block;
                }
                &-text {
                    margin-left: 6px;
                }
            }
            &-logo-responsive {
                display: none;
                width: 100%;
                align-items: center;
                &-icon {
                    display: block;
                }
                &-text {
                    margin-left: 6px;
                }
            }
            &-menu-button {
                display: block;
            }
             &-icon {
                display: block;
            }
        }
        &-list {
            margin: 0;
            padding-inline-start: 0;
            list-style-type: none;
            &--main {
                padding-top: 32px;
            }
            &-item {
                margin: 0 !important;
                &::before {
                    display: none !important;
                }
                &-link {
                    padding: 0 36px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 64px;
                    text-decoration: none;
                    &-left-section {
                        display: flex;
                        align-items: center;
                    }
                    &-right-section {
                        display: flex;
                        align-items: center;
                    }
                    &-icon {
                        display: block;
                        width: 16px;
                        margin-right: 20px;
                    }   
                    &-title {
                        display: block;
                        color: $black;
                    }
                    &-dropdown-icon {
                        @include transition(0.5s, linear);
                    }
                    &__active {
                        // border-left: 4px solid #2C81C1;
                        background: #2C81C1;
                        .dashboard-menu-section-list-item-link-title {
                            color: #FFFFFF;
                        }
                        .dashboard-menu-section-list-item-link-icon  path, .dashboard-menu-section-list-item-link-dropdown-icon path {
                            fill: #FFFFFF !important;
                        }
                    }
                    &__showed {
                        .dashboard-menu-section-list-item-link-dropdown-icon {
                            @include rotate(90deg);
                        }
                    }
                }
            }
        }
        &-sublist {
            list-style-type: none;
            font-size: 14px;
            line-height: 24px;
            padding-inline-start: 0;
            &-item {
                &-link {
                    display: block;
                    color: $grey900;
                    padding-left: 72px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    text-decoration: none;
                    &__active {
                        color: $brightBlue;
                    }
                }
            }
        }
        &-others-title {
            display: block;
            padding: 16px 36px 0;
            // font-family: TTInterphases;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1px;
            color: #9BA4B0;
        }
    }
    &-container {
        position: relative;
        width: 80%; //temprary
        padding-bottom: 96px;
        background-color: #E3EAED;
        transition: all 0.3s;
        &__close {
            width: calc(100% - 88px);
        }
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 72px;
            padding: 0 32px;
            background: #2C81C1;
            border-bottom: 1px solid $greyShadow;
            &-menu-title {
                margin: 0;
                font-weight: 500;
                font-size: 20px;
                line-height: 32px;
                color: $white;
            }
            &-menu-button {
                display: none;
            }
            &-right-side {
                display: flex;
                align-items: center;
            }
            &-user-basics {
                display: flex;
                margin-right: 32px;
            }
            &-user-date {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                margin-right: 12px;
            }
            &-full-name {
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: $white;
            }
            &-date {
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                color: $white;
            }
            &-user-image-container {
                flex-shrink: 0;
                width: 48px;
                height: 48px;
                @include border-radius(50%);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                overflow: hidden;
            }
            &-user-image {
                width: 48px;
                height: 48px;
                object-fit: cover;
            }
            &-notification {
                margin-right: 30px;
            }
            &-language {
                display: flex;
                align-items: center;
                margin-right: 28px;
                height: 100%;
                &-title {
                    margin-right: 10px;
                    color: $black;
                }
            }
            &-language-dropdown {
                position: absolute;
                top: 73px;
                right: 78px;
            }
        }
        &-main-section {
            display: flex;
            justify-content: space-between;
            padding: 28px 32px;
            box-sizing: border-box;
            background: #E3EAED;
            min-height: calc(100vh - 73px - 64px);
            border-radius: 4px;
            &-home-left {
                width: calc(70% - 16px);
            }
            &-dropdown {
                width: 100%;
                margin-bottom: 4px;
                border-radius: 4px!important;
                // overflow: hidden;
                &-header {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding-right: 16px;
                    padding-left: 16px;
                    
                    border-radius: 4px!important;
                    overflow: hidden;
                    div {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                    h5 {
                        margin-left: 12px;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        color: #FFFFFF;
                        margin-bottom: 0;
                    }
                    &-icon {
                        &-active {
                            transition-duration: 0.5s;
                            @include rotate(180deg);
                        }
                        &-deactive {
                            transition-duration: 0.5s;
                            @include rotate(0deg);
                        }
                    }
                    
                }
                &-accord {
                    width: 100%;
                    background-color: #2C81C1!important;
                    border-radius: 4px!important;
                    min-height: 50px!important;
                    overflow: hidden;
                    border-left: 4px solid #0179D2!important;
                    &-disable {
                        background-color: #fff!important;
                       
                    }
                }
            }
        }
        &-main-section-vertical {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 28px 32px 0;
            box-sizing: border-box;
            background: #E3EAED;
            
            // min-height: calc(100vh - 73px - 64px);
        }
    }
    &-news-section {
        display: flex;
        padding: 24px 0;
        margin: 0 32px;
        overflow-x: auto;
        &-item {
            flex-grow: 0;
            flex-shrink: 0;
            width: 52px;
            height: 52px;
            border: 1px solid #0066B2;
            border-radius: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }
    &-news-slider {
        height: 600px;
        width: 600px;
        &-item {
            display: flex;
            align-items: center;
            width: 600px !important;
            height: 600px !important;
        }
        &-image {
            width: 100%;
        }
        &-prev {
            position: absolute;
            top: 50vh;
            left: calc((100% - 600px) / 2 - 72px);
            z-index: 1;
        }
        &-next {
            position: absolute;
            top: 50vh;
            right: calc((100% - 600px) / 2 - 72px);
            z-index: 1;
        }
        &-arrow-image {
            path {
                @include transition(0.5s, linear);
            }
            &:hover {
                path {
                    fill: #2C81C1;
                }
            }
        }
        .slick-slide {
            &:focus, div {
              outline: none;
              border: 0;
            }
        }
        .slick-arrow.slick-prev, .slick-arrow.slick-next {
            display: none !important;
        }
    }
    &-selected-campaign{
        height: 600px;
        width: 50%;
        display: flex!important;
        justify-content: center;
        flex-direction: column;
        &-item{
            box-sizing: border-box;
            padding: 15px;
            background-color: #fff;
            width: 100%;
            height: 100%;
        }
        &-image {
            width: 100%;
            height: 100%;
        }
        &-close-btn{
            display: flex;
            align-items: center;
            justify-content: end;
            cursor: pointer;
            &-img{
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #0179D2;
            }
        }
        &-actions{
            background-color: #fff;
            border-top:2px solid #E8ECEF ;
            &-btn{
                width: 100%;
                  padding: 15px 0px;
                  color: #0179D2;
                  transition: .4s ease;
                &-continue{
                  border-left: 4px solid #E8ECEF;
                }
                &:hover{
                    background-color: #0179D2;
                    color: #fff;    
                }
                &-close{
                    text-transform: uppercase;
                }
            }
        }
    }
    &-cards-account-section {
        width: calc(60% - 16px);
        background: $white; //temporary
    }
    &-card-section {
        background: $greyBackground;
        padding-bottom: 28px;
        // @include border-radius(4px);
        &-background {
            background: $white;
        }
        .slick-dots li button::before {
            font-size: 12px;
            color: #d1d8dd;
            opacity: 0.7;
        }
    
        .slick-dots li.slick-active button::before {
            color: #0066B2;
            opacity: 1;
        }
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;
            &-left-side {
                display: flex;
                align-items: center;
            }
            &-amount-whole-part {
                font-size: 20px;
                font-weight: 400;
            }
            &-amount-fractional-part {
                font-size: 14px;
                // font-weight: 300;
                &::before {
                    content: ".";
                }
            }
            &-amount-currency {
                font-size: 20px;
                font-weight: 300;
                &__azn {
                    font-size: 24px;
                }
            }
            &-image {
                display: block;
                height: 24px;
                margin-right: 16px;
                background: #F8FAFB;
                @include border-radius(4px);
            }
            &-card-number {
                margin-bottom: 0;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: $black;
            }
        }
        
        &-content {
            &-slider-image {
                height: 34.65px;
                width: 20.09px;
            }
            &-slider-prev {
                position: absolute;
                top: calc((200px - 34.65px) / 2  + 40px);
                left: calc(-32px - 20.09px);
                z-index: 1;
            }
            &-slider-next {
                position: absolute;
                top: calc((200px - 34.65px) / 2  + 40px);
                right: calc(-32px - 20.09px);
                z-index: 1;
            }
            &-slider-arrow-image {
                path {
                    @include transition(0.5s, linear);
                }
                &:hover {
                    path {
                        fill: #2C81C1;
                    }
                }
            }
            &-slider-container {
                min-height: 200px;
                position: relative;
                margin: 0 auto;
                width: 370px;
                padding-top: 40px;
            }
            &-card {
                display: flex!important;
                flex-direction: column;
                position: relative;
                height: 200px;
                color: $white;
                margin-bottom: 32px;
                color: $white;
                width: 354px!important;
                margin-left: auto;
                margin-right: auto;
                overflow: hidden;
                border-radius: 15px;
                &-background {
                    width: 354px;
                    text-align: center;
                    z-index: -1;
                }
                &-item {
                    position: absolute;
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    box-sizing: border-box;
                    width: 354px;
                    height: 100%;
                    padding: 12px  24px 20px 36px;
                    margin: 0 auto;
                    &-name{
                        p{
                            margin: 5px 0px;
                            float: left;
                        }
                    }
                    &-logo {
                        align-self: flex-end;
                        display: block;
                        height: 32px;
                        float: right;
                    }
                    &-card-details-section {
                        display: flex;
                        justify-content: space-between;
                    }
                    &-card-number-content {
                        margin: 0;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 400;   
                    }
                    &-card-number-title {
                        margin: 0;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 300;
                    }
                    &-card-owner-section {
                        width: 60%;
                    }
                    &-card-valid-section {
                        width: 40%;
                    }
                    &-card-owner-name {
                        margin: 0;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 400;
                    }
                    &-card-owner-title {
                        margin: 0;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 300;
                    }
            
                    &-card-valid-date {
                        margin: 0;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 400;
                    }
                    &-card-valid-title {
                        margin: 0;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 300;
                    }
                    &-order-digital-card{
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        &-title,
                        &-btn{
                            letter-spacing: .5px;
                            font-size: 18px;
                        }
                        &-title{
                            margin-top: 14px;
                        }
                        &-btn{
                            background: rgba(255, 255, 255, 0.21);
                            border: 1px solid $white;
                            border-radius: 11px;
                            width: 100%;
                            color: $white;
                            padding: 10px 0px;
                            text-align: center;
                            &:hover{
                                color: $white;
                            }
                        }
                    }
                    &-add-card{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        &-btn{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &-icon{
                                color: $brightBlue;
                                font-size: 65px;
                                line-height: 72.66px;
                                margin: 0px;
                            }
                            &-title{
                                font-size: 24px;
                                color: $primaryBlack;
                                font-weight: 400;
                                line-height: 28.13px;
                            }
                        }
                    }
                }
            }
            &-button-section {
                position: relative;
                display: flex;
                justify-content: space-between;
                // align-items: center;
                width: 354px;
                margin: 0 auto;
                padding: 38px 0;
            }
            &-button-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;
            }
            &-button {
                padding: 8px 20px !important;
                font-size: 14px !important;
                line-height: 24px !important;
                border: 1px solid $grey200 !important;
                color: $black !important;
                border-radius: 2px !important;
                align-self: stretch !important;
                background: transparent !important;
                text-decoration: none;
                @include transition(.5s, ease);
                .MuiTouchRipple-root {
                    display: none;
                }
                &:hover {
                    color: #FFFFFF !important;
                    background: #2C81C1 !important;
                }
                &__disable {
                    pointer-events: none;
                    cursor: default;
                }
            }
            &-button-operation {
                position: absolute;
                top: 22px;
                right: 60px;
                display: none;
                flex-direction: column;
                width: 140px;
                padding-inline-start: 0;
                padding-left: 20px;
                padding-right: 20px;
                box-sizing: border-box;
                margin: 0;
                box-shadow: none;
                background: $white;
                box-shadow: 0px 0px 50px rgba(189, 195, 199, 0.3);
                @include transition(.5s, ease);
                &--show {
                    display: flex;
                }
                &-item {
                    padding: 12px 16px;
                    font-size: 14px;
                    list-style-type: none;
                    // cursor: pointer;
                    // color: $black;
                    @include transition(0.5s, linear);
                    &:not(:last-child) {
                        border-bottom: 1px solid $greyShadow;
                    }
                    // &:hover {
                    //     color: $infoFeedbackColor;
                    // }
                    &-link {
                        color: $black;
                        text-decoration: none;
                        text-align: left;
                        @include transition(0.5s, linear);
                        &:hover {
                            color: $infoFeedbackColor;
                        }
                        &__disable {
                            pointer-events: none;
                            cursor: default;
                        }
                    }
                }
            }
            .slick-slide {
                &:focus, div {
                  outline: none;
                  border: 0;
                }
            }
            .slick-arrow.slick-prev, .slick-arrow.slick-next {
                display: none !important;
            }
            &-add-card-background{
                width: 100%;
                height: 100%;
                background-color: $greyBackground;
            }
        }
    }
    &-add-new-card-section {
        background: #E3EAED;
        padding-bottom: 28px;
        @include border-radius(4px);
        &-background {
            background: $white;
        }
        &-header {
            display: flex;
            align-items: center;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;
            &-image {
                display: block;
                height: 24px;
                margin-right: 16px;
                background: #F8FAFB;
                @include border-radius(4px);
            }
            &-title {
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: $black;
                text-decoration:  none;
            }
        }
        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 0;
            &-button {
                text-decoration: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 354px;
                height: 200px;
                margin-bottom: 38px;
                &-background {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                }
                &-icon {
                    height: 60px;
                    margin-bottom: 16px;
                }
                &-title {
                    margin: 0;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                }
                &-subtitle {
                    margin: 0;
                    font-size: 14px;
                    line-height: 24px;
                    color: $grey800;
                }
            }
            &-invisible {
                display: flex;
                align-items: center;
                &-img-box {
                    flex-shrink: 0;
                    width: 40px;
                    height: 40px;
                    margin-right: 12px;
                    background: $greyShadow;
                    @include border-radius(50%);
                }
                &-content {
                    &-title {
                        width: 284px;
                        height: 8px;
                        margin-bottom: 8px;
                        background: $greyShadow;
                    }
                    &-subtitle {
                        width: 181px;
                        height: 8px;
                        background: $greyShadow;
                    }
                }
            }
        }
    }
    &-account-section {
        background: $white;
        @include border-radius(4px);
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;
            &-title {
                font-size: 16px;
                line-height: 24px;
                color: $black;
                margin-bottom: 0;
            }
            &-show-all {
                font-size: 14px;
                line-height: 24px;
                color: $black;
                text-decoration: none;
                @include transition(0.5s, linear);
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &-content {
            padding: 16px 24px 0;
            &-list {
                // margin: 0;
                padding-inline-start: 0;
                &-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 64px;
                    border-bottom: 1px solid $greyShadow;
                    text-decoration: none;
                    &-left-side {
                        display: flex;
                        align-items: center;
                    }
                    &-right-side {
                        color: $black;
                    }
                    &-invisible-account-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        width: 40px;
                        height: 40px;
                        margin-right: 16px;
                        background: $greyShadow;
                        @include border-radius(50%);
                        &-image {
                            height: 16px;
                        }
                    }
                    &-account-add {
                        margin: 0;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: $black;
                    }
                    &-account-number {
                        margin: 0;
                        font-size: 12px;
                        line-height: 16px;
                        color: $grey700;
                    }
                    &-amount-whole-part {
                        font-size: 16px;
                        font-weight: 400;
                      
                    }
                    &-amount-fractional-part {
                        font-size: 12px;
                        font-weight: 300;
                        &::before {
                            content: ".";
                        }
                    }
                    &-amount-currency {
                        font-size: 12px;
                        margin-left: 4px;
                        font-weight: 300;
                    }
                    &-invisible-account-add {
                       width: 210px;
                       height: 8px;
                       margin-bottom: 7px;
                       background: $greyShadow;
                    }
                    &-invisible-account-number {
                        width: 140px;
                        height: 6px;
                       background: $greyShadow;
                    }
                    &-invisible-amount {
                        width: 60px;
                        height: 8px;
                        margin-bottom: 8px;
                        background: $greyShadow;
                    }
                    &-invisible-currency {
                        width: 40px;
                        height: 6px;
                        background: $greyShadow;
                    }
                }
            }
        }
    }
    &-last-operations-section {
        width: calc(40% - 16px);
        background: $white;
        @include border-radius(4px);
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;
            &-title {
                font-size: 16px;
                line-height: 24px;
                color: $black;
                margin-bottom: 0;
            }
            &-history {
                font-size: 14px;
                line-height: 24px;
                color: $black;
                text-decoration: none;
                @include transition(0.5s, linear);
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 16px;
            &-image {
                margin: 16px 0 24px;
                width: 300px;
            }
            &-status {
                width: 216px;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 94px;
                text-align: center;
                color: $grey900;
            }
            &-invisible-list {
                padding-inline-start: 0;
                box-sizing: border-box;
                width: 70%;
                padding-right: 24px;
                padding-left: 24px;
                &-item {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 24px;
                    &-circle {
                        flex-shrink: 0;
                        width: 40px;
                        height: 40px;
                        margin-right: 12px;
                        background: $greyShadow;
                        @include border-radius(50%);
                    }
                    &-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                        &-title {
                            width: 100%;
                            height: 8px;
                            margin-bottom: 8px;
                            background: $greyShadow;
                        }   
                        &-subtitle {
                            width: 65%;
                            height: 8px;
                            background: $greyShadow;
                        }
                    }
                }
            }
        }
        // ==========================
        &-list {
            margin: 0;
            padding-inline-start: 0;
            width: 100%;
            padding: 0 24px;
            box-sizing: border-box;
        }
        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 64px;
            border-bottom: 1px solid $greyShadow;
            &-left-side{
                display: flex;
                width: 60%;
            }
            &-left-side {
               align-items: center;
            }
            &-right-side {
                width: 40%;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }
            &-content {
                width: calc(100% - 52px);
            }
            &-icon-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                background: #F0F7FC;
                // background: #E8ECEF;
                margin-right: 12px;
                @include border-radius(50%);
            }
            &-icon path {
                fill: #0179D2;
            }
            &-company {
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }
            &-description{
                text-overflow: ellipsis;
                overflow: hidden; 
                white-space: nowrap;
                width: 100%;
                font-size: 12px;
                line-height: 16px;
                color: $grey700;
            }
            &-amount {
                font-size: 16px;
                line-height: 24px;
                color: $black;
                text-align: right;
                &-whole-part {
                    font-size: 16px;
                    font-weight: 400;
                    &-redesign{
                        // &::before {
                        //     content: ".";
                        // }
                    }
                }
                &-fractional-part {
                    font-size: 12px;
                    font-weight: 300;
                    &::before {
                        content: ".";
                    }
                }
                &-currency {
                    font-size: 12px;
                    margin-left: 4px;
                    font-weight: 300;
                }
            }
            &-category {
                margin: 0;
                font-size: 12px;
                line-height: 16px;
                color: $grey700;
                text-align: right;
            }
        }
    }
    &-top-scroll-button {
        display: none;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        position: fixed;
        bottom: 28px;
        right: 28px;
        box-shadow: #2E3131;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        -webkit-box-shadow: 0px 8px 80px -4px rgba(0,0,0,0.59);
        -moz-box-shadow: 0px 8px 80px -4px rgba(0,0,0,0.59);
        box-shadow: 0px 8px 80px -4px rgba(0,0,0,0.59);
    }
    &-card-modal{
        width: 100%;
        background-color: $white;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 40px 35px;
        font-family: "Roboto", sans-serif;
        &-actions {
            &-item {
              display: flex;
              &-icon {
                width: 10%;
              }
              &-name {
                width: 90%;
                // a {
                //   color: $black;
                //   line-height: 24px;
                //   &:hover {
                //     color: $softBlue;
                //   }
                // }
              }
            }
          }
    }
    &-close-modal {
        display: flex;
        justify-content: center;
        margin-top: 55px;
        button {
          box-sizing: border-box;
          padding: 12px 24px;
          background-color: $softBlue;
          color: $white;
          border-radius: 2px;
        }
    }
}

.fatca {
    display: flex;
    flex-direction: column;
    &-navbar {
        &-container {
            display: flex;
            justify-content: space-between;
        }
        &-left {
            width: 20%
        }
        &-right {
            width: 80%;
        }
    }
    &-container {
        margin: 10px auto 0;
        width: 100%;
        max-width: 800px;
        &-content {
            height: calc(100vh - 160px - 82px);
        }
        &-footer {
            height: 160px;
            box-sizing: border-box;
            padding: 28px 0 44px;
            text-align: center;
            &-timer {
                margin: 0;
                color: #EF3E42;
                font-weight: 500;
            }
            &-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: #2E3131;
                margin: 0 0 16px;
            }
            &-button {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                border-radius: 4px;
                padding: 12px 24px;
                &-yes {
                    background: #FFFFFF;
                    color: #586268;
                }   
                &-no {
                    margin-left: 16px;
                    background: #0179D2;
                    color: #FFFFFF;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .dashboard {
        width: 100%;
        min-width: 10px!important;
        &-news-slider {
            width: 200px;
            &-item {
                width: 200px !important;
            }
            &-prev {
                left: calc((100% - 200px) / 2 - 48px);
            }
            &-next {
                right: calc((100% - 200px) / 2 - 48px);
            }
        }
        &-selected-campaign{
            width: 90%;
        }

      &-top-scroll-button {
          display: flex;
      }
      &-container {
          width: 100%;
          &-main-section {
              flex-direction: column;
              margin-top: 0;//conflict
          }
          &-header {
            //   position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              z-index: 100;
              box-sizing: border-box;
              &-user-basics {
                  display: none;
              }
              &-menu-title {
                  display: none;
              }
              &-menu-button {
                  display: flex;

              }
          }
      }
      &-cards-account-section {
          width: 100%;
      }
      &-last-operations-section { 
          width: 100%;
          margin-top: 28px;
      }
      &-menu-section {
        display: none;
        &-header {
            &-logo-responsive {
                display: flex;
                width: initial;
            }
        }
      }
      &-card-section { 
        &-content {
            width: 100%;
            &-slider-prev {
                position: absolute;
                display: none;
            }
            &-slider-next {
                display: none;
            }
            &-slider {
                width: 100%;
            }
            &-slider-container {
                width: 100%;
            }
            &-card {
                padding: 10px;
                width: calc(100vw -64);
                height: auto;
                &-background {
                    width: 100%;
                }
                &-item {
                   width: 100%;
                }
                h4 {
                    font-size: 21px;
                }
            }
            &-button-section {
                justify-content: space-evenly;
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                > div {
                    margin-top: 12px;
                }
                > a {
                    margin-top: 12px;
                }
            }
        }
      }
      &-last-operations-section {
        &-content {
            &-image {
                width: 76%;
                height: auto;
            }
        }
      }
      &-add-new-card-section {
          &-content {
              &-button {
                  width: calc(100vw - 84px);
                  height: calc((100vw - 84px)*0.6);
                  &-background {
                      width: 100%;
                      height: 100%;
                  }
              }
          }
      }
    }
    .nav-right-side-language {
        display: none;
    }
    .dashboard-container-main-section-home-left {
        width: 100%;
    }
    .dashboard-container-main-section {
        .dashboard-exchange-range-section-table {
            min-width: 340px;
        }
    }
    .fatca {
        width: 100%;
        min-width: 10px!important;
        &-navbar-left {
            display: none;
        }
    }
  @media (max-width: $breakpoint-mobile) {
    .dashboard {
        &-account-section {
            &-content {
                overflow-x: auto;
                &-list {
                    min-width: 420px;
                }
            }
        }
        &-last-operations-section {
            &-content {
                overflow-x: auto;
                //width: 100%;
            }
            &-list {
                
                li {
                    min-width: 420px;
                }
            }
        }
        &-selected-campaign{
            &-item{
                padding: 10px!important;
            }
        }
    }
  }
}

.full-height {
    height: 100%;
}

.deactive-card{
    background: white;
    opacity: 0.25;
}