@import "../../../../../copms/scss/variables.scss";

.ibank-card-limits__form__content {
  padding: 40px;
  height: 100%;
  @media (max-width: $breakpoint-XL) {
    padding: 20px 10px;
  }
  @media (max-width: $breakpoint-M) {
    padding: 40px;
  }
  @media (max-width: $breakpoint-S) {
    padding: 15px 5px;
  }
  &__header {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $softBlack;
    margin-bottom: 30px;
    @media (max-width: $breakpoint-XL) {
      margin-bottom: 20px;
    }
    @media (max-width: $breakpoint-S) {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  &__types {
    display: flex;
    border-top: 1px solid $greyShadow;
    border-bottom: 1px solid $greyShadow;
    margin-bottom: 40px;
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      width: 33%;
      padding: 22px;
      text-align: center;
      border-right: 1px solid $greyShadow;
      &:last-child {
        border: none;
      }
      &__title {
        font-size: 14px;
        margin-bottom: 6px;
        color: $gray400;
        @media (max-width: $breakpoint-S) {
          font-size: 16px;
        }
        @media (max-width: $breakpoint-XS) {
          font-size: 12px;
        }
      }
      &__amount {
        font-size: 20px;
        margin-bottom: 0px;
        color: $softBlack;
        @media (max-width: $breakpoint-XL) {
          font-size: 18px;
        }
        @media (max-width: $breakpoint-S) {
          font-size: 14px;
        }
      }
      @media (max-width: $breakpoint-M) {
        padding: 10px;
      }
    }
  }
  form {
    padding: 0px 10px;
    .ibank-card-limits__form__content {
      &__inputs {
        display: flex;
        justify-content: space-between;
        .dashboard-payment-container-data-section-content-container-form-input {
          font-size: 14px;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            // -webkit-appearance: none;
            margin-right: 15px;
          }
          &-box {
            background-color: $white;
          }
        }
        @media (max-width: $breakpoint-XL) {
          display: block;
          .dashboard-payment-container-data-section-content-container-form-input-group__grid {
            width: 100%;
          }
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-top: 230px;
        button {
          &:disabled {
            cursor: not-allowed;
            pointer-events: all !important;
          }
        }
        .dashboard-payment-container-data-section-content-container-form-footer-button {
          padding: 12px 55px;
        }
        @media (max-width: $breakpoint-XL) {
          margin-top: 30px;
        }
        @media (max-width: $breakpoint-S) {
          display: block;
        }
      }
      &__datepicker {
        padding-top: 48px;
        @media (max-width: $breakpoint-L) {
          padding-top: 20px;
        }
      }
    }
  }
  .ibank-loading {
    background-color: $gray200;
  }
}
