.dashboard-ips {
    &-container {
        &-radio-content {
            display: flex;
            flex-direction: row!important;
            align-items: center;
        }
        &-radio-h5 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            color: #252631;
            line-height: 40px;
            height: 30px;
            vertical-align: middle;
        }
    }
}

.tag {
    &-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        grid-column-gap: 6px;
        grid-row-gap: 6px;
    }
    &-item {
        display: flex;
        justify-content: space-between;
        background: #056CB8;
        color: #FFFFFF;
        line-height: 16px !important;
        font-size: 14px;
    }
    &-content {
        padding: 8px;
        margin: 0;
        background: #0179D2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-icon {
        padding: 0 8px;
    }
    &-select-box {
        .MuiFormControlLabel-root {
            margin-bottom: 0 !important;
        }
        .MuiFormControlLabel-root {
            margin-right: 0 !important;
        }
        &__checked {
            background: #F3F5F8;
            .dashboard-payment-container-data-section-content-container-form-select-box-list-item-button {
                color: #252631 !important;
            }
        }
       
}
}