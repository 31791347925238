.dashboard {
    &-settings-section {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        min-height: calc(100vh - 137px - 64px);
        @include border-radius(4px);
        column-gap: 30px;
        &-main-section {
            width: 75%;
            padding-left: 24px;
            padding-right: 60px;
            min-height: 650px;
            box-sizing: border-box;
            background: $white;

        }
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $greyShadow;
            // min-height: 56px;
            &-title {
                margin: 0;
                padding-top: 16px;
                padding-bottom: 15px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }
            &-profiletext {
                margin: 0;
                padding-top: 16px;
                padding-left: 32px;
                padding-bottom: 15px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }
            &-profile {
                background: #F8FAFB;
                border-bottom: 1px solid $greyShadow;

            }
        }
        &-profile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
            // border: 1px solid $greyShadow;
            &-left {
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;
                row-gap: 12px;
                width: 100%;
            }
            &-right {
               
            }
            &-circle {
                position: relative;
                width: 108px;
                height: 108px;
                @include border-radius(50%);
            }
            &-user-image-container {
                width: 108px;
                height: 108px;
                @include border-radius(50%);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                overflow: hidden;
            }
            &-user-image {
                width: 108px;
                height: 108px;
                object-fit: cover;
            }
            &-user-input {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 35px;
                height: 35px;
                @include border-radius(50%);
                z-index: 1;
                opacity: 0;
            }
            &-user-label {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
                bottom: 0;
                width: 35px;
                height: 35px;
                background: $softBlue;
                @include border-radius(50%);
                border: 3px solid $white;
                &-image {
                    width: 15px;
                }
            }
            &-user-data {
                // margin-left: 32px;
                &-name {
                    margin: 0;
                    font-size: 16px;
                    line-height: 30px;
                    letter-spacing: 0.2px;
                    color: $black;
                }
                &-title {
                    margin: 0;
                    font-family: Roboto;
                    font-size: 12px;
                    line-height: 24px;
                    color: $grey900;
                }
            }
            &-photo-delete {
                padding: 12px 24px;
                font-size: 16px;
                line-height: 24px;
                text-decoration: none;
                border-radius: 2px;
                display: flex;
                align-items: center;
                background: #EF3E42;
                &-icon {
                    margin-right: 4px;
                    flex-shrink: 0;
                }
                &-title {
                    color: #FFFFFF;
                }
            }
        }
        &-password {
            display: flex;
            &-change-section {
                width: 100%;
                // margin-right: 24px;
                // padding-bottom: 48px;
                box-sizing: border-box;
            }
            &-detail-section {
                width: 50%;
                margin-left: 24px;
                padding-top: 40px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                &-item {
                    display: flex;
                    align-items: center;
                }
                &-icon {
                    width: 14px;
                    margin-right: 16px;
                }
                &-content {
                    font-size: 14px;
                    line-height: 24px;
                    color: $black;
                }
            }
        }
        &-general {
            &-form {
                margin-bottom: 0;
                &-container {
                    display: flex;
                    justify-content: space-between;
                }
                &-group {
                    // width: calc(50% - 24px);
                    width: 100%;
                    padding-top: 16px;
                    &-label {
                        margin-bottom: 8px;
                        &-invisible {
                            min-height: 24px;
                        }
                    }
                    &-box {
                        border: 1px solid $greyShadow;
                        position: relative;
                        &-input {
                            width: 100%;
                            border: 0;
                            box-sizing: border-box;
                            padding: 12px 0 12px  16px;
                            @include border-radius(2px);
                            cursor: pointer;
                            
                        }
                        
                        &-selected {
                            position: relative;
                            width: 100%;
                            border: 0;
                            box-sizing: border-box;
                            padding: 12px 0 12px  16px;
                            @include border-radius(2px);
                            cursor: pointer;
                        }
                        &-list {
                            display: none;
                            position: absolute;
                            top: 48px;
                            left: -1px;
                            width: 100%;
                            padding-inline-start: 0;
                            margin: 0;
                            border: 1px solid $greyShadow;
                            background: $white;
                            @include border-radius(2px);
                            &-item {
                                list-style-type: none;
                                width: 100%;
                                border: 0;
                                box-sizing: border-box;
                                padding: 12px 0 12px  16px;
                                &:hover {
                                    background: $grey50;
                                }
                            }
                        }
                        &-icon {
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 48px;
                            height: 95%;
                            background: #FFFFFF;
                            &-img {
                                &__user {
                                    height: 16px;
                                }
                                &__envelope {
                                    height: 12px;
                                }
                                &__phone {
                                    height: 16px;
                                }
                                &__spinner {
                                    height: 16px;
                                    @include rotate360(1s);
                                }
                                &__angle-down {
                                    height: 6.5px;
                                }
                            }
                        }
                    }
                    &-code-section {
                        display: flex;
                        justify-content: space-between;
                    }
                    &-code-input {
                        min-width: 24px;
                        text-align: center;
                        height: 44px;
                        font-size: 40px;
                        height: 44px;
                        border: 1px solid $greyShadow;
                        color: $grey900;
                        &:not(:last-child) {
                            margin-right: 12px;
                        }
                    }
                    &-resend {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 13px;
                        &-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            &-icon {
                                display: flex;
                                align-items: center;
                                width: 24px;
                                height: 24px;
                                margin-right: 4px;
                                &-img {
                                    height: 16px;
                                }
                            }
                            &-button {
                                font-size: 14px;
                                line-height: 24px;
                                text-decoration-line: underline;
                                color: $grey900;
                            }
                        }
                        &-timer {
                            font-size: 14px;
                            line-height: 24px;
                            color: $grey900;
                        }
                    }
                    &-feedback {
                        position: relative;
                        &-message {
                            position: absolute;
                            top: 0;
                            left: 0;
                            box-sizing: border-box;
                            padding: 8px 0;
                            height: 32px;
                            margin: 0;
                            //font-family: TT Interphases; //temporary
                            font-size: 12px;
                            line-height: 16px;
                            &__text {
                                color: $textFeedbackColor;
                            }
                            &__info {
                                color: $infoFeedbackColor;
                            }
                            &__success {
                                color: $successFeedbackColor;
                            }
                            &__error {
                                color: $validationFeedbackColor;
                            }
                        }
                    }
                }
                &-button-section {
                    padding-top: 32px;
                    padding-bottom: 32px;

                }
                &-button {
                    padding: 12px 24px;
                    font-size: 16px;
                    line-height: 24px;
                    text-decoration: none;
                    @include border-radius(2px);
                    &__save {
                        background: $softBlue;
                        // border: 1px solid $softBlue;
                        color: $white;
                        margin-right: 16px;
                    }
                    &__cancel {
                        background: $grey100;
                        // border: 1px solid $grey100;
                        color: $grey900;
                    }
                    &:disabled {
                        background: $grey100;
                        // border: 1px solid $grey100;
                        color: $grey900;
                        cursor: default;
                    }
                }
            }
        }
        &-form {
            // margin-bottom: 0;
            padding-bottom: 48px;
            &-group {
                margin-top: 16px;
                width: 100%;
                &-label {
                    margin-bottom: 8px;
                }
                &-box {
                    border: 1px solid $greyShadow;
                    position: relative;
                    &-input {
                        width: calc(100% - 48px);
                        border: 0;
                        box-sizing: border-box;
                        padding: 12px 0 12px  16px;
                        @include border-radius(2px);
                        cursor: pointer;
                        &-label {
                            font-size: 12px;
                            color: #2E3131;
                            width: calc(100% - 48px);
                            border: 0;
                            box-sizing: border-box;
                            padding: 12px 0 0  16px;
                            @include border-radius(2px);
                        }
                    }
                    &-icon {
                        position: absolute;
                        top: 0px;
                        right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 48px;
                        height: 95%;
                        &-img {
                            &__password {
                                height: 11px;
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                            &__password-slash {
                                height: 12px;
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            &-validation-feedback {
                box-sizing: border-box;
                padding: 8px 0;
                height: 32px;
                //font-family: TT Interphases; //temporary
                font-size: 12px;
                line-height: 16px;
                &-message {
                    margin: 0;
                    color: $validationFeedbackColor;    
                }     
                &-success {
                    margin: 0;
                    color: $successFeedbackColor;
                }
            }
            &-button-section {
                padding-top: 12px;
            }
            &-button {
                padding: 12px 24px;
                font-size: 16px;
                line-height: 24px;
                text-decoration: none;
                @include transition(0.5s, linear);
                @include border-radius(2px);
                &__save {
                    background: $softBlue;
                    // border: 1px solid $softBlue;
                    color: $white;
                    margin-right: 16px;
                }
                &__cancel {
                    background: $grey100;
                    // border: 1px solid $grey100;
                    color: $grey900;
                }
                &:disabled {
                    background: $grey100;
                    // border: 1px solid $grey100;
                    color: $grey900;
                    cursor: default;
                }
            }
        }
        &-other-settings {
            &-image {
                width: 100%;
                margin-bottom: 32px;
            }
            &-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $black;
                margin: 0;
                margin-bottom: 8px;
            }
            &-content {
                font-size: 14px;
                line-height: 24px;
                color: $black;
                p {
                    margin: 0;
                    margin-bottom: 16px;
                }
            }
        }
        &-navbar-section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 35%;
            // padding-top: 32px;
            border-left: 1px solid $greyShadow;
            background: $white;
            &-item {
                display: flex;
                align-items: center;
                padding: 16px 20px;
                text-decoration: none;
                &-icon {
                    width: 16px;
                    margin-right: 20px;
                }
                &-title {
                    font-size: 14px;
                    line-height: 24px;
                    color: $grey900;
                }
                &--active {
                    border-left: 3px solid $softBlue;
                    background: #F3F5F8;
                    .dashboard-settings-section-navbar-section-item-title {
                        color: $softBlue;
                    }
                }
                &__disable {
                    pointer-events: none;
                    cursor: default;
                }
                  
            }
            &-item:hover {
                span {
                    color: #0179D2;
                }
            }
            &-bottom-section-item {
                padding: 8px 32px;
            }
            &-top-section{
                &-client-code{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    p{
                        display: grid;
                        font-weight: 500;
                        span{
                            font-weight: normal;
                            color: $softBlue
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: $breakpoint-tablet) {
    .dashboard {
        &-settings-section {
            flex-direction: column;
            &-main-section {
                order: 1;
                width: 100%;
            }
            &-navbar-section {
                flex-direction: row;
                order: 0;
                width: 100%;
                &-item {
                    align-items: center;
                    // border-left: 3px solid transparent;
                    box-sizing: border-box;
                    &-icon {
                        flex-shrink: 0;
                        margin-right: 16px;
                    }
                }
                &-top-section {
                    width: 100%;
                }
                &-bottom-section {
                    width: 50%;
                }
            }
            &-profile {
                // flex-direction: column;
                &-user-data {
                    margin-left: 16px;
                    // text-align: center;
                    &-name {
                        font-size: 16px;
                        line-height: 20px;
                    }
                    &-title {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
                &-circle {
                    // margin-bottom: 12px;
                }
            }
            &-main-section {
                padding-right: 24px;
            }
            &-general-form {
                &-container {
                    display: block;
                }
                &-group {
                    width: 100%;
                    &-box {
                        &-input {
                            padding-left: 8px;
                        }
                        &-icon {
                            width: 40px;
                        }
                    }
                }
                &-button {
                    width: 100%;
                    display: block;
                    text-align: center;
                    &__cancel {
                        margin-top: 12px;
                    }
                } 
                &-button-section {
                    margin-top: 36px;
                }
            }
            &-form {
                &-button {
                    width: 100%;
                    display: block;
                    text-align: center;
                    &__cancel {
                        margin-top: 12px;
                    }
                } 
            }
            &-password {
                flex-direction: column;
                &-change-section {
                    order: 1;
                    width: 100%;
                }
                &-detail-section {
                    order: 0;
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-mobile) {
    .dashboard-settings-section-profile {
        flex-direction: column;
        // align-items: flex-end;
        &-right {
            width: 100%;
        }
        &-photo-delete {
            margin-top: 16px;
        }
    }
    .dashboard-settings-section-navbar-section {
        flex-direction: column;
        &-top-section { 
            width: 100%;
            .dashboard-settings-section-navbar-section-item {
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }
        &-bottom-section {
            width: 100%;
            .dashboard-settings-section-navbar-section-item {
                padding-left: 20px;
                padding-top: 8px;
                padding-bottom: 8px;
            }
            .dashboard-settings-section-navbar-section-item-title {
                padding-left: 32px;
            }
        }
    }
}
.dashboard-settings-section-general-form-group-box-input.default, .dashboard-settings-section-form-group-box-input.default{
    cursor: inherit;
}