@font-face {
	font-family: 'icomoon';
	src:url('https://s3.amazonaws.com/icomoon.io/4/Loading/icomoon.eot?-9haulc');
	src:url('https://s3.amazonaws.com/icomoon.io/4/Loading/icomoon.eot?#iefix-9haulc') format('embedded-opentype'),
		url('https://s3.amazonaws.com/icomoon.io/4/Loading/icomoon.woff?-9haulc') format('woff'),
		url('https://s3.amazonaws.com/icomoon.io/4/Loading/icomoon.ttf?-9haulc') format('truetype'),
		url('https://s3.amazonaws.com/icomoon.io/4/Loading/icomoon.svg?-9haulc#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-spinner:before {
	content: "\e000";
}
.icon-spinner-2:before {
	content: "\e001";
}
.icon-spinner-3:before {
	content: "\e002";
}
.icon-spinner-4:before {
	content: "\e003";
}
.icon-spinner-5:before {
	content: "\e004";
}
.icon-spinner-6:before {
	content: "\e005";
}
.icon-spinner-7:before {
	content: "\e006";
}

@keyframes anim-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.spinner {
	display: inline-block;
    font-size:1.5em;
    line-height: 1;
	line-height: 1;
	animation: anim-rotate 2s infinite linear;
	color: #fff;
	text-shadow: 0 0 .25em rgba(255,255,255, .3);
}
.spinner--steps {
	animation: anim-rotate 1s infinite steps(8);
}
.spinner--steps2 {
	animation: anim-rotate 1s infinite steps(12);
}


footer {
	margin-top: 2em;
}