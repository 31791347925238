@import './../components/variables';

.dashboard-last-operations-section-item {
    padding: 8px 0;
    height: auto;
}

.dashboard {
    &-finance-section {
        &-invisible-cards-section {
            width: 100%;
            padding: 0 16px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;

            &-image {
                display: block;
                width: 100%;
                max-width: 527px;
                margin-bottom: 52px;
                margin-top: 80px;
            }

            &-paragraph {
                margin-bottom: 24px;
            }

            &-text {
                text-align: center;
                margin: 0;
                font-size: 16px;
                line-height: 24px;
                color: $grey900;
            }

            &-button {
                text-decoration: none;
                display: inline-block;
                padding: 16px 24px;
                background: $infoFeedbackColor;
                color: $white;
            }
        }

        &-cards-section {
            width: 100%;

            &-amount {
                &-whole-part {
                    font-size: 16px;
                    font-weight: 400;
                }

                &-fractional-part {
                    font-size: 12px;

                    // font-weight: 300;
                    &::before {
                        content: ".";
                    }
                }

                &-currency {
                    font-size: 20px;
                    font-weight: 300;

                    &__azn {
                        font-size: 24px;
                    }
                }
            }

            &-button-group {
                position: relative;
            }

            &-commission-alert {
                // width: calc(100% - 30px);
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 15px;
                margin-top: 15px;
                height: 56px;
                background: rgba(40, 115, 240, 0.1);
                border-radius: 2px;

                p {
                    font-family: 'Roboto';
                    margin: 0;
                    margin-left: 15px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    color: #2E3131;

                    span {
                        font-weight: 600;
                    }
                }
            }

            &-sms-table {
                width: 100%;
                border-collapse: collapse;

                tr {
                    border-bottom: 1px solid #E4E8EE;
                }

                td,
                th {
                    padding-left: 15px;
                    padding: 15px;
                }

                th {
                    text-align: left;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 24px;
                    color: #586268;
                }

                td {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    color: #2E3131;
                }
            }

            // &-button-operation {
            //     position: absolute;
            //     top: 12px;
            //     right: 44px;
            //     display: none;
            //     flex-direction: column;
            //     width: 140px;
            //     padding-inline-start: 0;
            //     padding-left: 20px;
            //     padding-right: 20px;
            //     box-sizing: border-box;
            //     margin: 0;
            //     box-shadow: 0px 0px 50px rgba(189, 195, 199, 0.3);
            //     background: $white;
            //     z-index: 70;
            //     &--show {
            //         display: flex;
            //     }
            //     &-item {
            //         padding: 12px 16px;
            //         font-size: 14px;
            //         list-style-type: none;
            //         cursor: pointer;
            //         color: $black;
            //         @include transition(0.5s, linear);
            //         &:not(:last-child) {
            //             border-bottom: 1px solid $greyShadow;
            //         }
            //         &:hover {
            //             color: $infoFeedbackColor;
            //         }
            //     }
            // }
            &-statistics-section {
                display: flex;
                justify-content: space-between;

                &-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: calc((100% - 64px) / 3);
                    padding: 12px 24px;
                    box-sizing: border-box;
                    background: $white;
                    margin-bottom: 28px;
                    @include border-radius(4px);

                    &-left-side {
                        width: 50%;
                    }

                    &-rigth-side {
                        width: 50%;
                    }

                    &-balance {
                        margin: 0;
                        font-size: 20px;
                        line-height: 32px;
                        color: $black;
                    }

                    &-currency {
                        margin: 0;
                        font-size: 14px;
                        line-height: 24px;
                        color: $grey600;
                    }

                    &-graphics {
                        width: 100%;
                    }
                }
            }

            &-statistics-icon {
                font-weight: 300;

                &__azn {
                    font-size: 24px;
                }
            }

            &-table-section {
                padding: 24px 0;
                @include border-radius(4px);
                background: $white;
                // min-height: calc(100% - 132px);  
                border: 0px solid black;

                &-order {
                    // padding: 24px 12px;
                }

                &-redesign {
                    padding-inline: 24px;
                }

            }

            &-table {
                display: table;
                flex-direction: column;
                width: 100%;
                border-collapse: collapse;
                border: 0px solid black;
                -webkit-border-horizontal-spacing: 0 !important;
                -webkit-border-vertical-spacing: 0 !important;
            }

            &-tbody {
                border: 0px solid black;
                -webkit-border-horizontal-spacing: 0 !important;
                -webkit-border-vertical-spacing: 0 !important;
            }

            &-row {
                justify-content: space-between;
                box-sizing: border-box;
                @include transition(0.5s, linear);

                &-order:not(:last-child) {
                    border-bottom: 1px solid $greyShadow;

                    // .dashboard-finance-section-cards-section-cell:nth-child(2) {
                    //     width: 30%;
                    // }
                }

                &-redesign {
                    cursor: pointer;

                    .dashboard-payment-section-content-title {
                        text-align: left;
                    }

                    .dashboard-finance-section-cards-section-cell-icon__circle-img {
                        height: 26px;
                    }

                    &:hover {
                        background: transparent !important;
                    }
                }

                &:hover {
                    background: $grey100;
                }

                &-line {
                    margin: 0 auto;
                    height: 1px;

                    .dashboard-finance-section-cards-section-cell {
                        height: 1px;
                        padding: 0;
                        background: $greyShadow;
                    }
                }
            }

            &-cells-container {
                border-bottom: 1px solid $greyShadow;
            }

            &-cell {
                padding: 16px 0;

                &-button {
                    background: $grey100 !important;
                    padding: 11px 20px;
                    color: $softBlue;
                    border-radius: 3px;
                    transition: .2s;
                    min-height: 50px;

                    &-contract {
                        &:hover {
                            background-color: $grey300 !important;
                        }
                    }

                    &-sign {
                        background-color: $softBlue !important;
                        color: $white;
                    }

                    img {
                        margin-right: 5px;
                    }
                }

                &__right {

                    // text-align: right;
                    .dashboard-finance-section-cards-section-button-group {

                        // text-align: right;
                        >div {
                            // max-width: 100% !important;
                        }
                    }
                }

                &-main-section {
                    margin-left: 24px;
                }

                &-icon {
                    align-self: center;

                    &__circle {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        background: #F0F7FC;
                        @include border-radius(50%);

                        &-img {
                            height: 20px;
                        }

                        // ===========================
                    }
                }

                &-title {
                    display: block;
                    margin: 0;
                    font-size: 14px;
                    line-height: 24px;
                    color: $grey600;
                    text-decoration: none;

                    &__disable {
                        pointer-events: none;
                        cursor: default;
                    }
                }

                &-content {
                    display: block;
                    margin: 0;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                    text-decoration: none;

                    &__disable {
                        pointer-events: none;
                        cursor: default;
                    }
                }

                &:first-child {
                    margin-left: 24px;
                    display: flex;
                    align-items: flex-start;
                }

                &:last-child {
                    margin-right: 24px;
                    align-self: center;
                }
            }

            &-header {
                box-sizing: border-box;
                padding-left: 30px;
                padding-bottom: 24px;
                border-bottom: 1px solid $greyShadow;

                h6 {
                    font-weight: 400;
                    margin: 0px;
                }
            }

            &-other-bank-cards-table {
                margin-top: 30px;
                padding: 24px 0;
                @include border-radius(4px);
                background: $white;
                border: 0px solid black;
            }

        }

        &-card-section {
            display: flex;
            justify-content: space-between;
            width: 100%;

            &-details-section {
                width: calc(50% - 16px);
                background: $greyBackground;

                &-background {
                    @include border-radius(4px);
                    background: $white;
                    min-height: 100%;
                }

                &-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    min-height: 56px;
                    padding: 0 24px;
                    border-bottom: 1px solid $greyShadow;

                    &-left-side {
                        display: flex;
                        align-items: center;
                    }

                    &-amount-whole-part {
                        font-size: 20px;
                        font-weight: 400;
                    }

                    &-amount-fractional-part {
                        font-size: 14px;

                        &::before {
                            content: ".";
                        }
                    }

                    &-amount-currency {
                        font-size: 20px;
                        font-weight: 300;

                        &__azn {
                            font-size: 24px;
                        }
                    }

                    &-image {
                        display: block;
                        height: 24px;
                        margin-right: 16px;
                        background: #F8FAFB;
                        @include border-radius(4px);
                    }

                    &-card-number {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: $black;
                        margin-bottom: 0;
                    }
                }

                &-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 32px 24px 0;

                    &-cashbacks {
                        row-gap: 1rem;
                        align-items: flex-end;
                        width: 70%;
                    }

                    &-cashback {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        width: 100%;

                        &-card {
                            display: flex;
                            position: relative;
                            width: 100%;
                            overflow: hidden;
                            padding: 20px;
                            border: 1px solid #E8ECEF;
                            border-radius: 6px;
                            align-items: flex-start;
                            gap: 1rem;

                            &-img {
                                width: 28px;
                                height: 28px;
                                border-radius: 50%;
                                position: relative;
                                object-fit: cover;
                                top: -4px;
                            }

                            &-content {
                                width: 100%;

                                &-info {
                                    display: flex;
                                    width: 80%;
                                    gap: 7rem;
                                    // justify-content: space-between;
                                    align-items: flex-end;

                                    p {
                                        color: #586268;
                                        font-size: 12px;
                                        margin: 0;

                                        &.condition {
                                            color: #898989;
                                        }

                                        &.color {
                                            font-size: 13px;
                                        }
                                    }

                                }

                                button {
                                    font-size: 14px;
                                    color: #0179D2;
                                    float: right;
                                }
                            }
                        }
                    }

                    &-flip {
                        max-width: 354px;
                        width: 100%;
                        height: 200px;
                        margin-bottom: 32px;

                        &:hover {
                            .dashboard-finance-section-card-section-details-section-content-flip-inner {
                                transform: rotateY(180deg);
                            }
                        }

                        &-hover {
                            .dashboard-finance-section-card-section-details-section-content-flip-inner {
                                transform: rotateY(180deg);
                            }
                        }

                        &-inner {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            transition: transform 0.6s;
                            transform-style: preserve-3d;
                            box-shadow: 15px 13px 8px 0 rgba(0, 0, 0, 0.2);
                            border-radius: 15px;

                            @media (max-width: 530px) {
                                box-shadow: none;
                            }
                        }
                    }

                    &-card {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        max-width: 354px;
                        width: 100%;
                        height: 200px;
                        margin-bottom: 32px;
                        color: $white;
                        overflow: hidden;
                        border-radius: 15px;

                        &-front {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            -webkit-backface-visibility: hidden;
                            backface-visibility: hidden;
                            color: $white;
                            overflow: hidden;
                            border-radius: 15px;

                            .dashboard-finance-section-card-section-details-section-header-amount-whole-part {
                                font-size: 24px;
                            }

                            .dashboard-finance-section-card-section-details-section-header-amount-fractional-part {
                                font-size: 16px;
                            }

                            .dashboard-finance-section-card-section-details-section-header-amount-currency {
                                font-size: 18px;
                                margin-left: .2rem;
                            }
                        }
                        
                        &-background {
                            margin-left: auto;
                            margin-right: auto;
                            width: 100%;
                            text-align: center;
                        }

                        &-item {
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            box-sizing: border-box;
                            width: 100%;
                            height: 100%;
                            padding: 12px 24px 20px 36px;
                            margin: 0 auto;

                            &-logo {
                                align-self: flex-end;
                                display: block;
                                height: 32px;
                            }

                            &-card-details-section {
                                display: flex;
                                justify-content: space-between;
                            }

                            &-card-number-content {
                                margin: 0;
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 32px;
                                font-weight: 400;
                            }

                            &-card-credit-type {
                                margin: 0;
                                margin-top: 52px;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 400;
                            }

                            &-card-number-title {
                                margin: 0;
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 300;
                            }

                            &-card-owner-section {
                                width: 60%;
                            }

                            &-card-valid-section {
                                width: 40%;
                            }

                            &-card-owner-name {
                                margin: 0;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 400;
                            }

                            &-card-owner-title {
                                margin: 0;
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: 300;
                            }

                            &-card-valid-date {
                                margin: 0;
                                // font-weight: 500;
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 400;

                            }

                            &-card-valid-title {
                                margin: 0;
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: 300;

                            }

                            &-account-number-section {
                                margin-top: 40px;
                            }

                            &-account-number-content {
                                margin: 0;
                                // font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 400;
                            }

                            &-account-number-title {
                                margin: 0;
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 300;
                            }
                        }
                    }

                    &-card-back {
                        // display: flex;
                        // flex-direction: column;
                        // position: relative;
                        // max-width: 354px;
                        // width: 100%;
                        // height: 200px;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;
                        // margin-bottom: 32px;
                        color: $white;
                        overflow: hidden;
                        border-radius: 15px;
                        // cursor: pointer;
                        transform: rotateY(180deg);

                        &-background {
                            margin-left: auto;
                            margin-right: auto;
                            width: 100%;
                            text-align: center;
                        }

                        &-item {
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            box-sizing: border-box;
                            width: 100%;
                            height: 100%;
                            padding: 12px 24px 20px 36px;
                            margin: 0 auto;
                            backdrop-filter: brightness(0.5);

                            &-logo {
                                align-self: flex-end;
                                display: block;
                                height: 32px;
                            }

                            &-card-details-section {
                                display: flex;
                                justify-content: space-between;
                            }

                            &-copy-button {
                                background-color: transparent !important;
                                margin-left: .2rem !important;
                                padding: 0 !important;
                                min-width: min-content !important;
                                // transition-duration: .6s;
                                transition: .2s;
                                padding-inline: 2px !important;
                                border-radius: 5px;
                                position: relative;

                                // transition-delay: .1s;
                                &:active {
                                    box-shadow: 0px 2px 10px 7px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
                                    //  0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
                                    // &::after {
                                    //     content: 'Copied';
                                    //     position: absolute;
                                    //     font-size: 10px;
                                    //     left: 21px;
                                    //     top: -6px;
                                    //     color: white;
                                    // }
                                }

                                abbr {
                                    cursor: pointer !important;
                                }
                            }

                            &-card-number-section {
                                display: flex;
                                gap: .3rem;
                            }

                            &-card-number-content {
                                margin: 0;
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 24px;
                                font-weight: 400;
                                cursor: auto;
                            }

                            &-card-credit-type {
                                margin: 0;
                                margin-top: 52px;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 400;
                            }

                            &-card-number-title {
                                margin: 0;
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 300;
                            }

                            &-card-owner-section {
                                width: 60%;
                            }

                            &-card-valid-section {
                                width: 40%;
                            }

                            &-card-owner-name {
                                margin: 0;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 400;
                            }

                            &-card-owner-title {
                                margin: 0;
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: 300;
                            }

                            &-card-valid-date {
                                margin: 0;
                                cursor: auto;
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 400;
                            }

                            &-card-valid-cvv {
                                margin: 0;
                                cursor: pointer;
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 400;
                                width: fit-content;
                            }

                            // &-card-valid-cvv-img {
                            //     background-color: #D9D9D9;
                            //     border-radius: 30px;
                            //     padding: 8px;
                            // }

                            &-card-valid-title {
                                margin: 0;
                                font-size: 13px;
                                line-height: 12px;
                                font-weight: 300;
                                display: inline-block;
                            }

                            &-account-number-section {
                                margin-top: 40px;
                            }

                            &-account-number-content {
                                margin: 0;
                                // font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 400;
                            }

                            &-account-number-title {
                                margin: 0;
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 300;
                            }
                        }
                    }

                    &-button-section {
                        width: 100%;
                        display: flex;
                        justify-content: space-evenly;
                    }

                    &-button-group {
                        position: relative;
                    }

                    &-button-container {
                        text-decoration: none;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        cursor: pointer;

                        &__disable {
                            pointer-events: none;
                        }
                    }

                    &-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 52px;
                        height: 52px;
                        border: 1px solid $greyShadow;
                        @include border-radius(50%);
                        @include transition(0.4s, linear);

                        .MuiButton-root {
                            width: 100%;
                            height: 100%;

                            &:hover {
                                background-color: transparent;
                            }
                        }

                        .dropdown-button-operation-list {
                            top: 30px;
                            left: 35px;
                        }

                        svg path {
                            @include transition(0.4s, linear);
                        }

                        &:hover {
                            border: 1px solid $infoFeedbackColor;
                            background: $infoFeedbackColor;

                            svg path {
                                fill: $white;
                            }
                        }

                        &__disable {
                            pointer-events: none;
                            background: rgba(1, 121, 210, 0.1);
                        }

                        &__block {
                            background: $validationFeedbackColor;
                            border: 1px solid $validationFeedbackColor;

                            path {
                                fill: $white;
                            }
                        }
                    }

                    &-button-operation {
                        position: absolute;
                        top: 24px;
                        left: 42px;
                        display: none;
                        flex-direction: column;
                        width: 140px;
                        padding-inline-start: 0;
                        padding-left: 20px;
                        padding-right: 20px;
                        box-sizing: border-box;
                        margin: 0;
                        box-shadow: none;
                        background: $white;
                        box-shadow: 0px 0px 50px rgba(189, 195, 199, 0.3);

                        &--show {
                            display: flex;
                        }

                        &-item {
                            padding: 12px 16px;
                            font-size: 14px;
                            list-style-type: none;
                            cursor: pointer;
                            color: $black;
                            @include transition(0.5s, linear);

                            &:not(:last-child) {
                                border-bottom: 1px solid $greyShadow;
                            }

                            &:hover {
                                color: $infoFeedbackColor;
                            }
                        }
                    }

                    &-title {
                        margin: 8px 0 0;
                        font-size: 14px;
                        line-height: 24px;
                        color: $grey900;
                    }

                    &-list {
                        width: 100%;
                        padding-inline-start: 0;
                        margin: 36px 0 0;
                        list-style-type: none;

                        &-item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 16px 0;
                            border-top: 1px solid $greyShadow;

                            &-title {
                                margin: 0;
                                font-size: 14px;
                                line-height: 24px;
                                color: $grey900;
                            }

                            &-content {
                                margin: 0;
                                font-size: 16px;
                                line-height: 24px;
                                color: $black;
                            }
                        }
                    }
                }
            }

            &-operation-sections {
                display: flex;
                flex-direction: column;
                width: calc(50% - 16px);
                gap: 1rem;
                justify-content: space-between;
            }

            &-operation-section {
                width: calc(50% - 16px);

                &-redesign {
                    width: 100%;
                    // svg{
                    //     cursor: pointer;
                    // }
                    // height: 390px;

                    .dashboard-finance-section-card-section-operation-section-background {
                        height: 100%;
                    }

                    .dashboard-last-operations-section-item-icon-container {
                        background: #EBECF5;
                        border-radius: 40px;
                    }

                    .dashboard-last-operations-section-header-title {
                        font-weight: 400;

                        span {
                            font-size: 12px;
                            color: #869AA8;
                            font-weight: 400;
                        }
                    }

                    .dashboard-last-operations-section-item-company-redesign {
                        color: #586268;
                        font-size: 15px;
                    }

                    .dashboard-last-operations-section-item {
                        border-bottom: 0;
                    }

                    .dashboard-last-operations-section-item:not(:first-child) {
                        border-top: 1px solid #E8ECEF;
                    }

                    &-background {
                        background: $white;
                        @include border-radius(4px);
                    }

                }

                &-background {
                    background: $white;
                    min-height: 100%;
                    @include border-radius(4px);
                }

                &-content {
                    padding: 16px 24px;
                    box-sizing: border-box;

                    &-redesign {

                        ul.dashboard-last-operations-section-list {
                            overflow-y: scroll;
                            height: 250px;
                            padding-right: 20px;
                        }

                        ::-webkit-scrollbar {
                            width: 5px;
                        }

                        ::-webkit-scrollbar-track {
                            background: none;
                        }

                        ::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                        }

                        ::-webkit-scrollbar-thumb:hover {
                            background: #0179D2;
                        }
                    }

                    &-type {
                        display: flex;
                        gap: 1rem;
                        font-size: 14px;
                        color: #2E3131;
                        padding: 0;
                        margin-right: 5px;

                        span {
                            width: 9px;
                            display: inline-block;
                            margin-right: 8px;
                            height: 9px;
                            border-radius: 50%;
                        }
                    }

                    &-pick-date {
                        margin-bottom: 24px;

                        &-title {
                            margin: 0 0 8px;
                            // font-family: TTInterphases; //temporary
                            font-size: 14px;
                            line-height: 24px;
                            color: $black;
                        }

                        &-input-section {
                            display: flex;
                            justify-content: space-between;
                        }

                        &-input {
                            width: calc(50% - 16px);

                            &.transfer-to-friend {
                                .MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
                                    border: none !important;
                                }
                            }

                            .MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
                                border: 1px solid $grey300 !important;
                                @include border-radius(2px);

                                &::before {
                                    display: none !important;
                                }

                                &::after {
                                    display: none !important;
                                }
                            }

                            .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                width: 48px;
                                height: 48px;
                                max-height: 48px;
                            }

                            .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
                                width: calc(100% - 48px - 16px);
                                padding: 12px 0 12px 16px;
                                // font-family: TTInterphases; //temporary
                                font-size: 14px;
                                height: 24px !important;
                                color: $black;
                            }

                            .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled {
                                display: none;
                            }

                            button.MuiButtonBase-root.MuiIconButton-root {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0;
                                border-left: 1px solid $grey300 !important;
                                border-radius: 0 !important;
                            }
                        }
                    }

                    .dashboard-last-operations-section-list {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }

        &-account-extract-section {
            width: 100%;

            &-search-section {
                display: flex;
                padding-bottom: 24px;
                background: $greyBackground;

                &-box {
                    display: block;
                    width: 220px;
                    background: $white;
                    border: 1px solid $greyShadow;
                    position: relative;

                    &-input {
                        width: 100%;

                        .MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
                            border: 1px solid $grey300 !important;
                            @include border-radius(2px);

                            &::before {
                                display: none !important;
                            }

                            &::after {
                                display: none !important;
                            }
                        }

                        .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                            width: 48px;
                            height: 48px;
                            max-height: 48px;
                        }

                        .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
                            // width: calc(100% - 48px - 16px);
                            padding: 12px 0 12px 16px;
                            // font-family: TTInterphases; //temporary
                            font-size: 14px;
                            height: 24px !important;
                            color: $black;
                        }

                        .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled {
                            display: none;
                        }

                        button.MuiButtonBase-root.MuiIconButton-root {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0;
                            border-left: 1px solid $grey300 !important;
                            border-radius: 0 !important;
                        }
                    }

                    &-selected {
                        position: relative;
                        width: 100%;
                        border: 0;
                        box-sizing: border-box;
                        padding: 12px 0 12px 16px;
                        @include border-radius(2px);
                        cursor: pointer;
                    }

                    &-list {
                        display: none;
                        position: absolute;
                        top: 42px;
                        left: -1px;
                        width: 100%;
                        padding-inline-start: 0;
                        margin: 0;
                        border: 1px solid $greyShadow;
                        background: $white;
                        @include border-radius(2px);

                        &-item {
                            list-style-type: none;
                            width: 100%;
                            border: 0;
                            box-sizing: border-box;
                            padding: 12px 0 12px 16px;

                            &:hover {
                                background: $grey50;
                            }
                        }

                        &--show {
                            display: block;
                        }
                    }

                    &-icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 42px;
                        height: 42px;

                        &-img {
                            &__user {
                                height: 16px;
                            }

                            &__envelope {
                                height: 12px;
                            }

                            &__phone {
                                height: 16px;
                            }

                            &__spinner {
                                height: 16px;
                                @include rotate360(1s);
                            }

                            &__angle-down {
                                height: 6.5px;
                            }
                        }
                    }

                    &-button {
                        display: inline-block;
                        padding: 16px 24px;
                        background: $infoFeedbackColor;
                        color: $white;
                    }

                    &:not(:last-child) {
                        margin-right: 24px;
                    }
                }
            }

            &-main-section {
                &--white {
                    @include border-radius(4px);
                    background: $white;
                }

                &-header {
                    display: flex;
                    justify-content: space-between;
                    // align-items: center;
                    min-height: 56px;
                    padding: 0 24px;
                    border-bottom: 1px solid $greyShadow;

                    &--left-side {
                        display: flex;
                        align-items: center;
                    }

                    &--right-side {
                        display: flex;
                        align-items: center;
                    }

                    &-history {
                        display: flex;
                        align-items: center;

                        &-icon {
                            margin-right: 12px;
                        }
                    }

                    &-download {
                        display: flex;
                        align-items: center;
                        margin-left: 32px;

                        &-icon {
                            path {
                                @include transition(0.5s, linear);
                            }
                        }

                        &-button {
                            margin-left: 24px;

                            &:not(:disabled):hover {
                                .dashboard-finance-section-account-extract-section-main-section-header-download-icon path {
                                    fill: $infoFeedbackColor;
                                }
                            }

                            &:disabled {
                                cursor: auto;
                            }
                        }

                        &-container {
                            &-icon {
                                margin-left: 8px;
                            }
                        }
                    }

                    &-title {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: $grey800;
                        margin-right: 8px;
                    }

                    &-content {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: $black;
                    }

                    &-image {
                        display: block;
                        height: 24px;
                        margin-right: 16px;
                        background: #F8FAFB;
                        @include border-radius(4px);
                    }

                    &-card-number {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: $black;
                    }
                }

                &-invisible-content-section {
                    display: flex;
                    box-sizing: border-box;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 50px;

                    &-image {
                        display: block;
                        width: 100%;
                        max-width: 224px;
                        margin-bottom: 52px;
                        margin-top: 80px;
                    }

                    &-paragraph {
                        margin-bottom: 24px;
                    }

                    &-text {
                        text-align: center;
                        margin: 0;
                        font-size: 16px;
                        line-height: 24px;
                        color: $grey900;
                    }

                    &-button {
                        display: inline-block;
                        padding: 16px 24px;
                        background: $infoFeedbackColor;
                        color: $white;
                    }
                }

                &-visible-content-section {
                    padding: 0 24px;
                }
            }

            &-list {
                list-style-type: none;
                padding-inline-start: 0;

                &-date-item {
                    padding: 8px 0;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                }

                &-extract-item {
                    padding: 12px 0;
                    border-bottom: 1px solid $greyShadow;
                    display: flex;
                    justify-content: space-between;
                    ;

                    &--left-side {
                        display: flex;
                        align-items: center;
                    }

                    &--right-side {
                        display: flex;
                        align-items: center;
                    }

                    &-icon-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        background: #F0F7FC;
                        margin-right: 32px;
                        @include border-radius(50%);
                    }

                    &-icon path {
                        fill: #0179D2;
                    }

                    &-date-container {
                        margin-right: 52px;
                    }

                    &-title {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: $black;
                    }

                    &-subtitle {
                        margin: 0;
                        font-size: 12px;
                        line-height: 16px;
                        color: $grey700;
                        text-align: right;

                        &__left {
                            text-align: left !important;
                        }
                    }

                    &-company {
                        font-size: 16px;
                        line-height: 24px;
                        color: $black;
                    }

                    &-purpose {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100%;
                    }

                    &-amount {
                        font-size: 16px;
                        line-height: 24px;
                        color: $black;
                        text-align: right;

                        &-whole-part {
                            font-size: 20px;
                            font-weight: 400;
                        }

                        &-fractional-part {
                            font-size: 14px;
                            font-weight: 300;

                            &::before {
                                content: ".";
                            }
                        }

                        &-currency {
                            font-size: 14px;
                            margin-left: 4px;
                            font-weight: 300;
                        }
                    }
                }
            }

            .dashboard-finance-section-card-section-operation-section-content-pick-date-input {
                width: 100%;

                .MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
                    border: none !important;
                }
            }
        }

        &-cards {
            height: calc(100% - 132px);
            overflow: scroll;

            &-section-table-section {
                // min-height: 100%;
            }
        }
    }

    &-bonuses-convertation {
        padding-bottom: 10px;
        border-bottom: 1px solid #E8ECEF;

        &-details {
            padding-inline-start: 0;
            list-style-type: none;

            &-item {
                margin-bottom: 30px;

                &-title {
                    margin: 0;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #2E3131;
                }

                &-content {
                    margin: 0;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 24px;
                    color: #252631;

                    &-icon {
                        position: relative;
                        top: -2px;
                        margin-left: 4px;
                    }
                }
            }
        }

        &-form {
            width: 70%;

            &-container {
                display: flex;
                justify-content: space-between;
            }

            &-group {
                width: calc(50% - 20px);
            }

            &-group-gap {
                padding-top: 48px;

                &-icon {
                    line-height: 48px;
                }
            }
        }
    }

    &-deposit {
        &-buttons {
            box-sizing: border-box;
            padding: 0 24px 24px;
            display: flex;
        }

        &-button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(128, 128, 128, 0.2);
            border-radius: 5px;

            &:not(:first-child) {
                margin-left: 20px;
            }

            &-circle {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 10px;
            }

            &-title {
                margin-bottom: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 32px;
                color: #2E3131;
            }

            &--active {
                background: #0179D2;

                .dashboard-deposit-button-title {
                    color: #FFFFFF;
                }
            }

            &--available {
                .dashboard-deposit-button-circle {
                    background: #11C042;
                }
            }

            &--progress {
                .dashboard-deposit-button-circle {
                    background: #FFC700;
                }
            }

            &--reject {
                .dashboard-deposit-button-circle {
                    background: #F44730;
                }
            }
        }
    }

    &-finance-cards-route {
        .dashboard-cards-add-new-card-btn {
            color: $softBlue;
            margin-right: 20px;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }
    }

    &-container-main-section-cards {
        height: calc(100vh - 73px - 64px);
    }
}

$breakpoint-tablet: 768px;
$breakpoint-mobile: 530px;

@media (max-width: $breakpoint-tablet) {

    .dashboard-card-section-header-card-number,
    .dashboard-finance-section-card-section-details-section-header-card-number {
        // display: none;
    }

    .dashboard-finance-section-account-extract-section-main-section-header-history {
        display: none;
    }

    .dashboard-finance-section-account-extract-section-main-section-header-title {
        display: none;
    }

    .dashboard-finance-section {
        &-card-section {
            flex-direction: column;

            &-details-section {
                width: 100%;
            }

            &-operation-section {
                width: 100%;
                margin-top: 15px;
            }
        }
    }

    .dashboard-finance-section-cards-section-statistics-section {
        flex-direction: column;

        &-item {
            width: 100%;

            // padding:12px 0 ;
            // flex-direction: column;
            &-balance {
                // font-size: 16px;
            }

            &-left-side {
                // width: 74%;
            }
        }
    }

    .dashboard-finance-section-account-extract-section-main-section-header-card-number {
        word-break: break-all;
        min-width: 50px;
    }

    .dashboard-finance-section-account-extract-section-main-section {
        overflow-x: auto;

        > {
            min-width: 550px;
        }

        &-header-card-number {
            font-size: 12px;
        }
    }

    .dashboard-template-section-content {
        &-button {
            flex-direction: column;

            &> {
                width: 100%;
                margin-right: 0;
                text-align: center;
                align-items: center;
            }
        }
    }

    .dashboard-header-route-left {
        h2 {
            font-size: 18px;

        }
    }

    .dashboard-header-route-finance {
        @media (max-width: $breakpoint-mobile) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .dashboard-finance-section-card-section-details-section-content-button {
        &-section {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        &-container {
            width: 33.33%;
            margin-bottom: 16px;
        }
    }

    .dashboard-bonuses-convertation-form {
        width: 100%;
    }
}

@media (max-width: $breakpoint-mobile) {
    .dashboard-header-route-left {
        h2 {
            font-size: 16px;

        }
    }

    .dashboard-header-route-link {
        margin-right: 30px;
    }

    .dashboard-header-route-button {
        margin-left: 0px;
        font-size: 14px;
        text-transform: capitalize !important;
    }

    .dashboard-finance-section-cards-section-statistics-section {
        &-item {
            &-balance {
                font-size: 14px;
            }
        }
    }

    .dashboard-finance-section-cards-section-table-section {
        overflow-x: auto;
    }

    .dashboard-finance-section-cards-section-table {
        min-width: 520px;
    }

    .dashboard-finance-section-card-section-details-section {
        &-content {
            &-button {
                width: 42px;
                height: 42px;

                &-container {
                    width: 33.33%;
                }
            }

            &-card {
                height: auto;

                &-item {
                    &-card-number-content {
                        font-size: 17px;
                    }
                }
            }

            &-list-item-content {
                word-break: break-all;
                font-size: 14px;
            }
        }

        &-header {
            &-card-number {
                word-break: break-all;
            }
        }
    }

    .dashboard-finance-section-account-extract-section {
        &-search-section {
            flex-direction: column;

            &-box {
                width: 100%;
                margin-right: 0;
                margin-bottom: 15px;
            }
        }

        &-main-section {
            overflow-x: auto;

            > {
                min-width: 530px;
            }

            &-header-card-number {
                word-break: break-all;
            }
        }
    }

    .dashboard-finance-section-card-section-operation-section-content {
        overflow-x: auto;
    }

    .dashboard-finance-section-account-extract-section-main-section-header-card-number {
        word-break: break-all;
    }

    .dashboard-bonuses-convertation-form {
        &-container {
            flex-direction: column;
        }

        &-group {
            width: 100%;
        }

        &-group-gap {
            display: none;
        }
    }
}

.show-vat-info-inland {
    background: #E3F1FB;
    padding: 10px;
    overflow: hidden;

    &-details {
        padding-left: 2.4rem;

        &-label {
            font-size: 14px;
            margin: 0;
        }

        p {
            overflow: hidden;
        }
    }

    label {
        margin-right: 0;
        margin-bottom: 0;
    }

    .MuiIconButton-label {
        svg {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 10px;
                height: 10px;
                background-color: green;
                z-index: 10;
            }

            // fill: #C5C5C5;
        }
    }

}