@import "../../Home//scss/style.scss";

.ibank-auth__header {
  margin-top: 74px;
  margin-bottom: 46px;
  line-height: 24px;
  color: $primaryBlack;
  &__title {
    font-size: 20px;
  }
  &__desc {
    font-size: 14px;
    color: $grey800;
  }
  @media screen and (min-width: 320px) and (max-width: 900px) {
    margin-top: 0px;
    margin-bottom: 36px;
    &__title {
      text-align: center;
      font-weight: 700;
    }
  }
}
