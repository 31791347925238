.ibank-pin-instruction {
  padding: 0px 24px 24px;
  box-sizing: border-box;
  &__content {
    box-sizing: border-box;
    padding-left: 10px;
    color: #404040;
    &__header {
      line-height: 19px;
      margin-top: 16px;
    }
    &__step {
      margin-top: 24px;
      h6 {
        margin-bottom: 12px;
      }
      &__card {
        background: #f9fafb;
        border: 1px solid #f1f1f1;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 0px 20px;
        &__details {
          box-sizing: border-box;
          padding: 12px 0px 12px;
          p {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    button {
      box-sizing: border-box;
      padding: 12px 42px;
      border-radius: 2px;
      cursor: pointer;
    }
    &__continue {
      background: #0179d2;
      color: white;
      margin-right: 16px;
    }
    &__cancel {
      color: #586268;
      background: #f3f5f8;
    }
  }
}
@media screen and (max-width: 768px) {
  .ibank-pin-instruction {
    font-size: 14px;
    &__content {
      padding-left: 0px;
    }
    &__actions {
      button {
        padding: 15px;
      }
    }
  }
}
@media screen and (max-width: 530px) {
  .ibank-pin-instruction {

    &__actions {
      button {
        width: 50%;
      }
    }
  }
}