@import "../../../../../../copms/scss/variables.scss";

%text-styles {
  line-height: 24px;
  margin: 0;
}

.ibank-card-limits__tabs__item {
  padding: 16px 0px;
  border-bottom: 1px solid $greyShadow;
  position: relative;
  &:last-child {
    border-bottom: none;
  }
  @media (max-width: $breakpoint-S) {
    padding: 12px 0px;
  }
  &__content {
    cursor: pointer;
    transition: 0.5s ease;
    padding-right: 50px;
    &:hover {
      .ibank-card-limits__tabs__item__content__header {
        color: $infoFeedbackColor;
      }
      .ibank-card-limits__tabs__item__content__icon {
        svg {
          path {
            fill: $infoFeedbackColor;
          }
        }
      }
    }
    &__header,
    &__desc {
      @extend %text-styles;
    }
    &__header {
      color: $softBlack;
    }
    &__desc {
      color: $gray300;
    }
    &__icon {
      position: absolute;
      top: 35%;
      right: 3%;
    }
  }
}

.color-blue {
  color: $infoFeedbackColor;
}
