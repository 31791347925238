@import "./../../../../Home/scss/components/variables";

.dashboard-digital-card-rules-modal {
  height: 90vh;
  padding: 20px;
  object {
    height: 90%;
  }
  .dashboard-close-modal {
    margin-top: 20px;
    justify-content: flex-end;
    .disagree-btn {
      background-color: transparent;
      color: $softBlue;
      padding: 12px 0px;
      margin-right: 25px;
    }
  }
}
