.dashboard {
    &-template-section {
        margin-bottom: 28px;
        background: $white;
        @include border-radius(4px);

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;

            &-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }

            &-history {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $black;
                text-decoration: none;
                @include transition(0.5s, linear);

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
            padding: 28px 24px;

            &-button {
                display: flex;
                box-sizing: border-box;
                align-items: center;
                text-decoration: none;
                border: 1px solid #E8ECEF;
                width: calc((100% - 4 * 24px) / 5);
                padding: 20px 12px;
                margin-bottom: 24px;
                @include border-radius(4px);

                &:not(:nth-child(5n)) {
                    margin-right: 24px;
                }

                &__left-side {
                    flex-shrink: 0;
                    margin-right: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    background: #F0F7FC;
                    @include border-radius(50%);
                }

                &__right-side {
                    width: calc(100% - 52px);
                }

                &-icon-section__new {
                    background: #D6EEFF;
                }

                &-redesign {
                    display: flex;
                    box-sizing: border-box;
                    align-items: center;
                    border: none;
                    width: inherit;
                    padding: 0;
                    padding-bottom: 16px;
                    &-icon {
                        width: 58px;
                        height: 58px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;
                        background: rgba(27, 117, 187, 0.1);
                        border-radius: 8px;
                    }
                }
            }

            &-icon {
                height: 20px;
            }

            &-title {
                width: 100%;
                margin: 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                color: #2E3131;

                &__invisible {
                    height: 8px;
                    width: 100%;
                    background: #E8ECEF;
                    margin-bottom: 8px;
                }
            }

            &-amount-section {
                &__invisible {
                    height: 8px;
                    width: 70%;
                    background: #E8ECEF;
                }

                margin: 0;
            }

            &-amount {
                font-size: 14px;
                line-height: 24px;
                color: #586268;
            }

            &-currency {
                margin-right: 4px;
                font-size: 18px;
                line-height: 24px;
                color: #586268;

                &__azn {
                    font-size: 20px;
                }
            }
        }
    }
}

.disabled-date-picker {
    .MuiInputBase-adornedEnd {
        .Mui-disabled {
            color: #ACB3B8 !important;
        }
    }
}