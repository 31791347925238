@import "../../../../../copms/scss/variables.scss";

.ibank__tabs {
  padding: 10px 0;
  border-radius: 4px;
  background: #ffffff;
  min-height: calc(100% - 132px);
  .MuiButtonBase-root {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: normal;
    line-height: 24px;
    padding: 12px 0px;
    color: $gray300;
    margin-right: 20px;
    opacity: 1;
  }
  .MuiTabs-flexContainer {
    padding-left: 24px;
  }
  .MuiTabPanel-root {
    padding: 12px 0px 12px 26px;
  }
  .Mui-selected {
    color: $black;
  }
  .MuiTabs-indicator {
    background-color: $infoFeedbackColor;
  }
}
