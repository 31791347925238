@import "./../../../../Home/scss/components/variables";

%text-style {
  color: $grey900;
  font-size: 14px;
}

.ibank-digital-card-info {
  padding: 0px 32px 32px;
  box-sizing: border-box;
  background-color: $grey50;

  &-title {
    margin-bottom: 24px;
    color: $primaryBlack;
  }

  &-image {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      margin-left: 22px;
    }
  }

  &-desc {
    margin-bottom: 28px;

    p {
      @extend %text-style;
    }
  }

  &-tariffs {
    &-list {
      padding-left: 0px;

      &-item {
        display: flex;
        border-bottom: 1px solid $greyShadow;
        padding: 12px 0px;

        &:last-child {
          border-bottom: none;
        }

        &-name {
          @extend %text-style;
          width: 50%;
        }

        &-value {
          color: $black;
          width: 50%;
          text-align: end;
        }

        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 959px) {
  .ibank-digital-card-info {
    &-image {
      img {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ibank-digital-card-info {
    padding: 0px 50px 32px;
  }
}

@media screen and (max-width: 600px) {
  .ibank-digital-card-info {
    padding: 0px 32px 32px;

    &-title {
      margin-bottom: 15px;
    }

    &-desc,
    &-tariffs {
      h5 {
        font-size: 16px;
      }
    }

    &-tariffs {
      &-list {
        &-item {
          &-value {
            font-size: 14px;
          }
        }
      }
    }

    &-image {
      img {
        width: 100%;
      }
    }
  }
}