@import "./../../../../Home/scss/components/variables";

.ibank-card-storage-add-card {
  .dashboard-container-main-section {
    .dashboard-finance-section-cards-section-table-section {
      width: 100%;
      padding-top: 0px;
    }
  }

  &-header {
    border-bottom: 1px solid $greyShadow;
    padding: 16px 32px;

    h6 {
      font-weight: normal;
      color: $black;
      line-height: 24px;
      margin: 0px;
    }
  }

  &-content {
    box-sizing: border-box;
    padding: 32px;

    &-desc {
      p {
        color: $black;
        line-height: 24px;
        font-weight: 500;
      }
    }

    form {
      .header-sign-section-container-form-group {
        padding-top: 24px;
      }

      input {
        width: 100%;

        &:read-only {
          color: $grey600;
          background-color: $grey50;
        }
      }
    }
  }

  &-form-actions {
    margin-top: 32px;
  }
}

@media screen and (max-width: 320px) {
  .ibank-card-storage-add-card {
    &-header {
      padding: 16px 15px;
    }

    &-content {
      padding: 15px;
    }

    &-form-actions {
      button {
        margin-right: 0px;
      }
    }
  }
}