.custom-calendar {
    border-radius: 4px;
    // width: 428px;
    background-color: #fff;
    .head {
        height: 56px;
        line-height: 56px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px solid #E8ECEF;
        h3 {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            margin: 0;
            color: #2E3131;
        }
    }
    .body {
        width: calc(100% - 40px);
        margin: 20px;
        padding-bottom: 20px;
        height: auto;
        .days-grid {
            display: grid;
            grid-template-columns: repeat(7, 1fr);

            .day {
                height: 45px;
                display: grid;
                justify-content: center;
                align-items: center;
                background: #fff;
                padding: 1px;
                background-clip: content-box;
                border-radius: 5px;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                /* identical to box height, or 114% */

                text-align: center;

                color: #586268;
              }
              
            .day:not(.today):hover {
                background: #fff;
                background-clip: content-box;
            }
            
            .holiday {
                /* background: linear-gradient(135deg, #ff0000ab, #ffc0e3); */
                /* background-clip: content-box; */
            }
            
            .today {
                background: rgba(0, 102, 178, 0.1);
                border-radius: 2px;
                background-clip: content-box;
            }
        }
    }
    .weeks {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        span {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */


            color: #586268;

        }
    }
}