.dashboard {
    &-statistics-section {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 48px;
        &-content {
           width: 50%;
           &-list {
               list-style-type: none;
               padding-inline-start: 0;
               &-item {
                margin: 8px 16px;
                &-color {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin-right: 8px;
                    @include border-radius(50%);
                    &__rest {
                        background: #FFAB2B;
                    }
                    &__paid {
                        background: #0179D2;
                    }
                }
                &-title {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    color: #2E3131;
                   }
               }
           }
    //        text::before {
    //         content:"";
    //         top: 0;
    //         left: 0;
    //         position: relative;
    //         width: 100px;
    //         height: 100px;
    //         display: inline-block;
    //         background: red;
    //         box-shadow: 0px 8px 25px rgba(152, 169, 188, 0.3);
    //         border-radius: 13px;
    //         padding: 6px 16px;
    //    }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .dashboard {
        &-statistics-section {
            padding-left: 16px;
            padding-right: 16px;
            &-content-list-item {
                margin-left: 0;
            }
            // dashboard-statistics-section-content-list-item
        }
        .dashboard-finance-section-card-section-details-section-header-title {
            margin-bottom: 0;
        }
    }

  }