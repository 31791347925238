::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    @include border-radius(28px);
}  
::-webkit-scrollbar-track {
    background: $grey400;
}
::-webkit-scrollbar-thumb {
    background: rgba(152, 152, 152, 0.2); 
    cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
    background: $softBlue; 
}
::placeholder {
    color: $grey500;
    opacity: 1; 
}

:-ms-input-placeholder {
    color: $grey500;
}

::-ms-input-placeholder {
    color: $grey500;
}
body {
    width: 100%;
    color: $black;
}
button {
    border: 0;
    background: transparent;
    padding: 0;
    cursor: pointer;
    &:focus {
        outline: 0;
    }
}
