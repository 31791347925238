@import "./../../../../Home/scss/components/variables";

.dashboard-add-new-card-modal {
  .dashboard-card-modal {
    &-title {
      margin-bottom: 55px;
    }
    &-actions {
      &-item {
        margin-bottom: 30px;
        &-name {
          padding-bottom: 16px;
          border-bottom: 1px solid $greyShadow;
          a {
            color: $black;
            line-height: 24px;
            &:hover {
              color: $softBlue;
            }
          }
        }
      }
    }
  }
}
