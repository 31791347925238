@import "./../../../copms/scss/variables.scss";

.ibank-otp {
  &__body {
    input {
      width: 48px !important;
      margin: 0 12px;
      font-size: 36px;
      border: none;
      color: $grey500;
      border-bottom: 3px solid $grey500;
      line-height: 62px;
    }
  }
  &__resend {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button,
    &__timer {
      font-size: 14px;
      line-height: 24px;
      color: $softBlue;
    }
    button {
      text-decoration: underline;
      cursor: pointer;
      &:disabled {
        color: $grey900;
      }
    }
    &__timer {
      margin: 0;
    }
  }
}
