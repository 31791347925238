// COLORS
$infoFeedbackColor: #0179d2;
$softBlue: #0179d2;
$grey900: #586268;
$greyShadow: #e8ecef;
$grey500:#BDC3C7;
$gray400: #849097;
$gray300: #959fa5;
$gray200: #f8fafb;
$grey100: #f3f5f8;
$white: #ffffff;
$black: #2e3131;
$softBlack: #404040;
$error: #e54143;
$errorShadow: #e24d4d;
$success: #18bd71;
$successShadow: #2ec47e;
$warning: #f0ad4e;
$warningShadow: #f2bc27;
$info: #4da1ff;
$infoShadow: #5faaff;

// MEDIA QUERY SIZES
$breakpoint-XS: 320px;
$breakpoint-S: 480px; //for mobile devices
$breakpoint-M: 768px; //fot iPads,tablets
$breakpoint-L: 1024px; //for small screens,laptops
$breakpoint-XL: 1200px;
