.dashboard {
    &-contact-section {
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 32px 24px;
        &-title {
            margin-bottom: 32px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #2E3131;
        }
        &-input {
            margin-bottom: 16px;
            width: 100%;
            border: 1px solid #E4E8EE;
            box-sizing: border-box;
            border-radius: 2px;
            padding: 12px 16px;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            color: $black;
            resize: none;
        }
        &-textarea {
            height: 250px;
        }
        &-button {
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            background: #0179D2;
            border-radius: 2px;
            padding: 12px 24px;
            &:disabled {
                color: #586268;                
                background: #F3F5F8;            
            }
        }
        &-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-inline-start: 0;
            list-style-type: none;
            &-item {
                display: flex;
                align-items: center;
                width: calc(50% - 16px);
                margin-bottom: 32px;
                &-icon {
                    flex-shrink: 0;
                    height: 32px;
                    margin-right: 16px;
                }
                &-text {
                    padding-left: 12px;
                    border-left: 1px solid #BDC3C7;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    color: #2E3131;
                }
            }
        }
        &-form {
            width: calc(50% - 16px);
        }
        &-info {
            width: calc(50% - 16px);

        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .dashboard {
        &-contact-section {
            flex-direction: column;
            &-list {
                flex-direction: column;
                &-item {
                    width: 100%;
                }
            }
            &-form {
                width: 100%;
            }
            &-info {
                width: 100%;
            }
            &-button {
                width: 100%;
                margin-bottom: 32px;
            }
        }
        &-header-list {
            &-title {
                display: none;
            }
            &-image {
                margin-right: 0;
            }
            &-item {
                &:not(:first-child) {
                    margin-left: 8px;
                }
                justify-content: flex-end;
                &:not(:last-child) {
                    margin-right: 0;
                }
            }
        }
    }
}