.ibank-login__register {
  margin-top: 32px;
  span {
    margin-right: 7px;
  }
  a {
    text-decoration: underline !important;
    // color: #999999;
  }
}
