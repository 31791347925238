@import "./../../../Home/scss/components/variables";

.ibank-digital-card {
  .dashboard-container-main-section {
    .dashboard-finance-section-cards-section-table-section {
      width: 100%;
      padding: 0px;
      overflow: hidden;
    }
  }

  &-header {
    border-bottom: 1px solid $greyShadow;
    box-sizing: border-box;
    padding: 16px 0px;

    h6 {
      font-weight: 400;
      color: $black;
      line-height: 24px;
      margin: 0px;
    }
  }
}

@media screen and (max-width: 600px) {
  .ibank-digital-card {
    .dashboard-header-route {
      &-left {
        button {
          img {
            width: 70%;
          }
        }

        h2 {
          font-size: 17px;
        }
      }

    }

    &-header {
      h6 {
        font-size: 14px;
      }
    }
  }
}