.ibank-pin-settings__header {
  border-bottom: 1px solid #f2f4f6;
  box-sizing: border-box;
  padding: 0px 0px 10px;
  h6 {
    font-weight: 400;
    color: #2e3131;
    line-height: 24px;
  }
}
