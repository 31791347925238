@import "./../../../../Home/scss/components/variables";

.dashboard-digital-card-info-modal {
  .dashboard-card-modal {
    &-title {
      margin-bottom: 33px;
    }
    &-actions {
      &-item {
        margin-bottom: 20px;
        border-bottom: 1px solid $greyShadow;
        &-name {
          padding-bottom: 13px;
          button {
            color: $secondaryBlack;
            line-height: 24px;
            &:hover {
              color: $softBlue;
            }
          }
          p {
            font-size: 14px;
            color: $grey700;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
