.ibank-pin-settings__actions {
  padding: 0px 12px;
  .MuiGrid-item {
    margin: 12px 0px;
    button {
      width: 75%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px;
      height: 100%;
      border: 1px solid #e8ecef;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        box-shadow: 0px 4px 40px rgba(51, 51, 51, 0.1);
      }
      span {
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 12px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .ibank-pin-settings__actions {
    .MuiGrid-item {
      margin: 12px 15px;
      button {
        padding: 10px;
        width: 100%;
        span {
          font-size: 12px;
        }
      }
    }
  }
}
