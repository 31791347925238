.dashboard {
    &-notifications-section {
        padding-inline-start: 0;
        position: absolute;
        top: 72px;
        right: 0;
        z-index: 1000;
        background: #FFFFFF;
        // width: 100%;
        width: 636px;
        box-shadow: 0px 0px 10px rgba(189, 195, 199, 0.3);
        height: 0;
        overflow: hidden;
        transition: all 0.6s;
        &__visible {
            height: auto;
            max-height: 340px; //temporary;
        }
        &-item {
            display: flex;
            align-items: center;
            min-height: 60px;
            padding: 12px 16px;
            width: 100%;
            box-sizing: border-box;
            &-icon {
                margin-right: 24px;
                flex-shrink: 0;
                align-self: center;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                background: #F0F7FC;
                @include border-radius(50%);
                &-img {
                    height: 20px;
                }
            } 
            &-content {
                width: calc(100% - 24px - 32px - 12px);
            }
            &-title {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: #849097;
                text-overflow: ellipsis;
                overflow: hidden; 
                white-space: nowrap;
                width: 100%;
                &__unread {
                    color: #2E3131;
                }
            }
            &-description {
                margin: 0;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: #849097;
                text-overflow: ellipsis;
                overflow: hidden; 
                white-space: nowrap;
                width: 100%;
                &__unread {
                    color: #586268;
                }
            }
            &-button {
                width: 100%;
                padding: 16px 0;
                text-align: center;
                color: #0179D2;
                background: #F3F5F8;
            }
        }
    }
    &-notification-section {
        display: flex;
        // background: #FFFFFF;
        &-left {
            width: 35%;
            border-right: 1px solid #E4E8EE;
            background: #FFFFFF;
        }
        &-right {
            padding: 24px;
            width: 65%;
            box-sizing: border-box;
            background: #FFFFFF;
        }
        &-header {
            width: 100%;
            padding: 14px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 0px 10px rgba(189, 195, 199, 0.3);
            &-left {
                display: flex;
                align-items: center;
            }
            &-right {
                display: flex;
                align-items: center;
            }
            &-button {
                &:last-child {
                    margin-left: 24px;
                }
            }
            &-title {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 24px;
                color: #586268;
            }
        }
        &-list {
            padding-inline-start: 0;
            list-style-type: none;
            margin: 0;
            // height: calc(100vh - 248px);
            max-height: 640px;
            overflow: auto;
        }
        &-item {
            display: flex;
            border-top: 1px solid #E4E8EE;
            padding: 8px 24px;
            &__selected {
                background: #F3F5F8;
            }
            &-title {
                margin: 0;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
            }
            &-description {
                margin: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: #586268;
            }
        }
        &-image {
            width: 100%;
            margin-bottom: 16px;
        }
        &-title {
            margin: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
        }
        &-subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #586268;
        }
        &-content {
            margin: 0;
            margin-top: 16px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            text-align: justify;
            color: #2E3131;
        }
        &-header {
            min-height: 56px;
        }
    }
}


$breakpoint-tablet: 768px;
$breakpoint-mobile: 530px;
@media (max-width: $breakpoint-tablet) {
    .dashboard-notification-section {
        display: flex;
        flex-direction: column;
        &-left {
            width: 100%;
        }
        &-right {
            width: 100%;
            margin-top: 20px;
        }
    }
}