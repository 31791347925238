.dashboard {
    &-container-mobile {
        position: relative;
        width: 80%; //temprary
        padding-bottom: 96px;
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 72px;
            padding: 0 32px;
            background: $white;
            border-bottom: 1px solid $greyShadow;
            &-menu-title {
                margin: 0;
                font-weight: 500;
                font-size: 20px;
                line-height: 32px;
                color: $black;
            }
            &-right-side {
                display: flex;
                align-items: center;
            }
            &-user-basics {
                display: flex;
                // margin-right: 32px;
                padding: 16px 24px;
            }
            &-user-date {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &-full-name {
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }
            &-date {
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                color: #586268;
            }
            &-user-image-container {
                flex-shrink: 0;
                width: 48px;
                height: 48px;
                @include border-radius(50%);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                overflow: hidden;
                margin-right: 12px;
            }
            &-user-image {
                width: 48px;
                height: 48px;
                object-fit: cover;
            }
        }
    }
    &-menu-mobile-section {
        display: none;
        position: absolute;
        top: 72px;
        left: -100%;
        width: 100%; //temprary
        border-right: 1px solid $greyShadow;
        background: $white;
        z-index: 1000;
        height: 100vh;
        overflow: hidden;
        transition: all 0.6s;
        &__visible {
            left: 0;
            overflow: auto;
        }
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 32px;
            height: 72px;
            background: $white;
            border-bottom: 1px solid $greyShadow;
            &-logo {
                width: 100%;
                display: flex;
                align-items: center;
                &-icon {
                    display: block;
                }
                &-text {
                    margin-left: 6px;
                }
            }
            &-menu-button {
                display: block;
            }
             &-icon {
                display: block;
            }
        }
        &-list {
            margin: 0;
            padding-inline-start: 0;
            padding: 0 24px;
            list-style-type: none;
            &-item {
                border-top: 1px solid $greyShadow !important;
                margin: 0 !important;
                &::before {
                    display: none !important;
                }
                &-link {
                    padding: 0 36px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 64px;
                    text-decoration: none;
                    &-left-section {
                        display: flex;
                        align-items: center;
                    }
                    &-right-section {
                        display: flex;
                        align-items: center;
                    }
                    &-icon {
                        display: block;
                        width: 16px;
                        margin-right: 20px;
                    }   
                    &-title {
                        display: block;
                        color: $black;
                    }
                    &-dropdown-icon {
                        @include transition(0.5s, linear);
                    }
                    &__active {
                        border-radius: 4px;
                        background: #2C81C1;
                        .dashboard-menu-mobile-section-list-item-link-title {
                            color: #FFFFFF;
                        }
                        .dashboard-menu-mobile-section-list-item-link-icon  path {
                            fill: #FFFFFF;
                        }
                        .dashboard-menu-mobile-section-list-item-link-dropdown-icon path {
                            fill: #FFFFFF;
                        }
                    }
                    &__showed {
                        .dashboard-menu-section-list-item-link-dropdown-icon {
                            @include rotate(90deg);
                        }
                        .dashboard-menu-mobile-section-list-item-link-dropdown-icon {
                            @include rotate(90deg);
                        }
                    }
                }
            }
        }
        &-sublist {
            list-style-type: none;
            font-size: 14px;
            line-height: 24px;
            padding-inline-start: 0;
            &-item {
                &-link {
                    display: block;
                    color: $grey900;
                    padding-left: 72px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    text-decoration: none;
                    &__active {
                        color: $brightBlue;
                    }
                    &::before {
                        margin-right: 20px;
                        content: "";
                        border-radius: 50%;
                        width: 9px;
                        height: 9px;
                        background: #2C81C1;
                        display: inline-block;
                    }
                }
            }
        }
        &-others-title {
            display: block;
            padding: 16px 36px 0;
            // font-family: TTInterphases;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1px;
            color: #9BA4B0;
        }
        &-languages {
            display: flex;
            justify-content: center;
            padding: 48px 24px;
            font-size: 16px;
            line-height: 24px;
            color: $black;
            &-button:not(:last-child):after {
                content: "|";
                margin: 0 5px;
            }
        }
    }
}
@media (max-width: $breakpoint-tablet) {
    .dashboard {
        &-menu-mobile-section {
            display: block;
        }
    }
}