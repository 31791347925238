@mixin border-radius($x) {
    -webkit-border-radius: $x;
       -moz-border-radius: $x;
            border-radius: $x; 
}
@mixin user-select($x) {
    -webkit-user-select: $x;
       -moz-user-select: $x;
        -ms-user-select: $x;
            user-select: $x;
}
@mixin rotate($x) {
    -webkit-transform: rotate($x);
        -ms-transform: rotate($x);
            transform: rotate($x);
}
