@import "../../../../copms/scss/variables.scss";

%common-background {
  width: 50%;
  @media (max-width: $breakpoint-M) {
    width: 100%;
  }
}

.ibank-card-limits {
  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: $white;
    border-radius: 5px;
    overflow: hidden;
    @media (max-width: $breakpoint-M) {
      display: block;
      width: 100%;
    }
  }
  &__tabs {
    @extend %common-background;
    background-color: $white;
    @media (max-width: $breakpoint-M) {
      &__responsive {
        display: none;
      }
    }
  }
  &__form {
    @extend %common-background;
    background-color: $gray200;
    border-left: 2px solid $greyShadow;
    @media (max-width: $breakpoint-M) {
      border-left: none;
    }
  }
  @media (max-width: $breakpoint-M) {
    .dashboard-container-main-section {
      min-height: auto;
    }
  }
}
