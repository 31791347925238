.dashboard {
  &-header-route {
    padding: 0 32px;
    margin-top: 28px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: none;
    justify-content: space-between;
    &-button {
      text-decoration: none;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff !important;
      background: #0179d2;
      border-radius: 2px;
      padding: 10px 30px;
      margin-left: 30px;
    }
    &-left {
      display: flex;
      align-items: center;
      flex-direction: row;
      min-height: 36px;
      h2 {
        margin: 0px 16px 0px 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;
        color: #2e3131;
      }
    }
    &-link {
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #2e3131;
      text-transform: uppercase;
    }
  }
}
