.dashboard-services-header-link {
  &__tab {
    display: inline-block;
    .dashboard-transfer-list-header-link {
      margin-right: 6px;
    }
  }
  &__tab {
    margin-right: 32px;
  }
  &__active__tab {
    border-bottom: 2px solid #0179d2;
  }
  &__active {
    color: #2e3131;
  }
}
.ibank-services {
  .ibank-services__radio-btns {
    display: flex;
    .dashboard-payment-container-data-section-content-container-form-radioBtn {
      display: inline-block;
      margin-right: 30px;
      .dashboard-payment-container-data-section-content-container-form-radioBtn-title {
        font-size: 14px;
      }
    }
  }
}
