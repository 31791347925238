.dashboard {
    &-campaigns-section {
        width: 100%;
        padding: 32px 24px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 4px;
        &-item {
            overflow: hidden;
            width: calc(50% - 16px);
            margin-bottom: 32px;
            border-radius: 4px;
            text-decoration: none;
            overflow: hidden;
            @include transition(.6s, ease);
            &-image {
                width: 100%;
                height: 224px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
            &-content {
                padding: 28px;
            }
            &-title {
                margin: 0;
                margin-bottom: 12px;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                color: #2E3131;
            }
            &-subtitle {
                margin: 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical; 
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                text-align: justify;
                color: #586268;
            }
            &:hover {
                box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07), 
                0 32px 64px rgba(0,0,0,0.07);
            }
        }
    }
    &-campaign-section {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 4px;
        &-image {
            width: 100%;
            height: 324px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 4px;
        }
        &-subtitle {
            margin-top: 32px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: justify;
            color: #2E3131;
        }

    }
    &-campaigns-slider {
        margin-top: 28px;
        background: #FFFFFF;
        border-radius: 4px;
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;
            &-title {
                font-size: 16px;
                line-height: 24px;
                color: $black;
                margin-bottom: 0;
            }
            &-history {
                font-size: 14px;
                line-height: 24px;
                color: $black;
                text-decoration: none;
                @include transition(0.5s, linear);
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &-content {
            position: relative;
            padding-bottom: 72px;
            &-border {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    width: 24px;
                    background: #FFFFFF;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    width: 24px;
                    background: #FFFFFF;
                }
            }
        }
        &-item {
            background: #FFFFFF;
            display: flex !important;
            padding: 24px 24px 32px;
            box-sizing: border-box;
            text-decoration: none;
            &-image {
                display: block;
                width: 96px;
                height: 96px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border-radius: 4px;
                margin-right: 24px;
            }
            &-content {
                width: calc(100% - 130px);
                &-title {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #0066B2;
                    text-decoration: none;
                }
                &-subtitle {
                    margin: 0;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical; 
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: justify;
                    color: #586268;
                }
            }
        }
        .slick-prev:before, .slick-next:before{
            display: none;
        }
        
        .slick-dots {
            li button {
                &::before {
                    opacity: 1;
                    font-size: 12px;
                    color: #ACB3B8;
                }
            }
            .slick-active button {
                &::before {
                    color: #0179D2;
                }
            }
        }
    }
}
@media (max-width: $breakpoint-tablet) {
    .dashboard {
        &-campaigns-section {
            &-item {
                width: 100%;
            }
        }
    }
}