@import 'components/normalize';
@import 'components/variables';
@import 'components/mixins';
@import 'components/common';

@-ms-keyframes pullRight { 
    from { 
        right: 100%;
    } to { 
        right: 0  
    }
}
@-o-keyframes pullRight {
    from { 
        right: 100%; 
    } to { 
        right: 0  
    }
}
@-moz-keyframes pullRight { 
    from { 
        right: 100%; 
    } to { 
        right: 0  
    }
}
@-webkit-keyframes pullRight { 
    from { 
        right: 100%; 
    } to { 
        right: 0 
    }
}
@keyframes pullRight { 
    from { 
        right: 100%; 
    } to { 
        right: 0 
    }
}

// ===================

@-ms-keyframes pullLeft { 
    from { 
        left: 100%; 
    } to { 
        left: 0  
    }
}
@-o-keyframes pullLeft {
    from { 
        left: 100%; 
    } to { 
        left: 0  
    }
}
@-moz-keyframes pullLeft { 
    from { 
        left: 100%; 
    } to { 
        left: 0  
    }
}
@-webkit-keyframes pullLeft { 
    from { 
        left: 100%; 
    } to { 
        left: 0  
    }
}
@keyframes pullLeft { 
    from { 
        left: 100%; 
    } to { 
        left: 0  
    }
}

// ===================

@-ms-keyframes pullTop { 
    from { 
        top: 100vh; 
    } to { 
        top: 0  
    }
}
@-o-keyframes pullTop {
    from { 
        top: 100vh; 
    } to { 
        top: 0  
    }
}
@-moz-keyframes pullTop { 
    from { 
        top: 100vh; 
    } to { 
        top: 0  
    }
}
@-webkit-keyframes pullTop { 
    from { 
        top: 100vh; 
    } to { 
        top: 0 
    }
}
@keyframes pullTop { 
    from { 
        top: 100vh; 
    } to { 
        top: 0 
    }
}

// ===================

body {
    position: relative;
    font-family: 'Roboto', sans-serif;
}
input:not([type='checkbox']), textarea, label:not(.header-sign-section-container-form-additional-agree-label) {
    display: block;
    color: $black;
    &:focus {
        outline: 0;
    }
}
.page-container {
    // width: 100%;
    box-sizing: border-box;
    // width: calc(100% - 2 * #{$containerHorizontalPadding});
    // padding-left: $containerHorizontalPadding;
    // padding-right: $containerHorizontalPadding;
    padding: $containerVerticalPadding $containerHorizontalPadding;
    font-family: 'Roboto', sans-serif; //temporary
    background-repeat: no-repeat, no-repeat;
    background-position: right center, center;
    // min-height: 100vh;
    &-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        mix-blend-mode: multiply;
        &-image {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    background-color:  #E5E5E5;
}
.nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // padding-top: 40px;
    &-left-side {
        text-align: right;
        // OLD LOGO HEIGHT
        // height: 44px;

        height: 170px;
        left: -2%;
        position: relative;
        // -webkit-animation: pullRight 0.5s ease-out;
        //    -moz-animation: pullRight 0.5s ease-out;
        //         animation: pullRight 0.5s ease-out;
    }
    &-right-side {
        display: flex;
        align-items: center;
        position: relative;
        // -webkit-animation: pullLeft 0.5s ease-out;
        //    -moz-animation: pullLeft 0.5s ease-out;
        //         animation: pullLeft 0.5s ease-out;
        &-phone {
            display: flex;
            margin-right: 32px;
            text-decoration: none;
            &-icon {
                display: block;
                height: 24px;
            }
            &-text {
                display: block;
                margin-left: 10px;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: $white;
                //font-family: TT Interphases; //temporary
            }
        }
        &-register {
            display: block;
            margin-right: 32px;
            padding: 8px 25px;
            font-size: 14px;
            border: 1px solid $white;
            box-sizing: border-box;
            color: $white;
            text-decoration: none;
            @include border-radius(2px);
            @include transition(0.5s, linear);
            &:hover {
                background: $white;
                color: $black;
            }
        }
        &-language {
            display: none;
            align-items: center;
            color: $white;
            &-title {
                margin-right: 10px;
            }
            &-dark {
                .nav-right-side-language-title {
                    color: $black;
                }
                .nav-right-side-language-dropdown-icon {
                    path {
                        fill: $black;
                    }
                }
            }
        }
    }
}
.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // min-height: calc(100vh - 94px - 125px - 20px); //temporary
    background-color: $white;
    // padding: 100px 0px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    &-main-section {
        box-sizing: border-box;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 0;
        width: 65%;
        background-repeat: no-repeat, no-repeat;
        background-position: right center, center;
        height: 100%;
        padding: 140px;
        // -webkit-animation: pullRight 0.8s ease-out;
        //    -moz-animation: pullRight 0.8s ease-out;
        //         animation: pullRight 0.8s ease-out;
        &-slogan {
            font-weight: 900;
            font-style: normal;
            font-size: 64px;
            line-height: 76px;
            color: $white;
            position: relative
        }
        &-description {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: $white;
            position: relative;
        }
    }   
    &-sign-section {
        position: relative;
        // overflow: hidden;
        // -webkit-animation: pullLeft 0.5s ease-out;
        //    -moz-animation: pullLeft 0.5s ease-out;
        //         animation: pullLeft 0.5s ease-out;
        width: 35%;
        min-height: 75vh;
        height: 100%;
        &-customer {
            padding-bottom: 16px;
            padding-top: 70px;
            text-align: center;
            &-type {
                text-decoration: none;
                font-size: 20px;
                line-height: 28px;
                color: $black;
                font-weight: 300;
                cursor: pointer;
                @include transition(0.3s, linear);
                &__slash {
                    margin: 16px;
                    font-weight: 700;
                }
                &--active {
                    font-weight: 900;
                }
                &:hover {
                    color: $black;
                }
            }
        }
        &-container {
            background: $white; //temporary
            // box-sizing: border-box;
            padding:  26px 70px 70px;
            border-radius: 4px;
            &-sign {
                position: relative;
                display: flex;
                width: 100%;
                padding-bottom: 30px;
                &-type {
                    display: block;
                    width: 50%;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    border: 0;
                    font-size: 16px;
                    color: rgba(46, 49, 49, 0.5);
                    border-bottom: 1px solid $greyShadow; 
                    text-decoration: none;
                    text-align: center;
                    &:hover {
                        color: $primaryBlack;
                    }
                    &--active {
                        color: $black;
                    }
                    &__underline{
                        content: "";
                        position: absolute;
                        bottom: 30px;
                        display: inline-block;
                        width: 50%;
                        height: 3px;
                        background: $softBlue;
                        @include transition(0.2s, linear);
                    }
                    &__underline-asan {
                        left: 0;
                    }
                    &__underline-mobile {
                        left: 50%;
                    }
                    &__not-a-bank-customer{
                        margin: 0px;
                        border-bottom: 3px solid $softBlue;
                    }
                }
            }
            &-form {
                margin-bottom: 0;
                &-group {
                    padding-top: 16px;
                    &-label {
                        margin-bottom: 8px;
                    }
                    &-box {
                        border: 1px solid $greyShadow;
                        position: relative;
                        &-input {
                            width: calc(100% - 42px);
                            border: 0;
                            box-sizing: border-box;
                            padding: 12px 0 12px  16px;
                            @include border-radius(2px);
                        }
                        &-icon {
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 42px;
                            height: 42px;
                            &-img {
                                &__phone {
                                    height: 16px;
                                }
                                &__user {
                                    height: 14px;
                                }
                                &__password {
                                    height: 11px;
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                                &__password-slash {
                                    height: 12px;
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
                &-validation-feedback {
                    box-sizing: border-box;
                    padding: 8px 0;
                    height: 32px;
                    //font-family: TT Interphases; //temporary
                    font-size: 12px;
                    line-height: 16px;
                    color: $validationFeedbackColor;    
                    &-message {
                        margin: 0;
                    }     
                }
                &-additional {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 12px;
                    &-remember {
                        display: flex;
                        align-items: center;
                        &-label {
                            display: block;
                            position: relative;
                            padding-left: 35px;
                            line-height: 25px;
                            cursor: pointer;
                            @include user-select(none);
                        }
                        &-checkbox {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }
                        &-checkmark {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 25px;
                            width: 25px;
                            background-color: $greyBackground;
                            @include transition(0.6s, linear);
                            border-radius: 2px;
                        }
                        //.header-sign-section-container-form-additional-remember-label:hover .header-sign-section-container-form-additional-remember-checkbox ~ .header-sign-section-container-form-additional-remember-checkmark {
                        //    background-color: #ccc; //
                        //}
                        .header-sign-section-container-form-additional-remember-label .header-sign-section-container-form-additional-remember-checkbox:checked ~ .header-sign-section-container-form-additional-remember-checkmark {
                            background-color: $softBlue;
                        }
                        .header-sign-section-container-form-additional-remember-checkmark:after {
                            content: '';
                            position: absolute;
                            display: none;
                            width: 8px !important;
                            height: 15px !important;
                            left: 9px !important;
                            top: 3px !important;
                        }
                        .header-sign-section-container-form-additional-remember-label .header-sign-section-container-form-additional-remember-checkbox:checked ~ .header-sign-section-container-form-additional-remember-checkmark:after {
                            display: block;
                        }
                        .header-sign-section-container-form-additional-remember-label .header-sign-section-container-form-additional-remember-checkmark:after {
                            left: 9px;
                            top: 5px;
                            width: 5px;
                            height: 10px;
                            border: solid white;
                            border-width: 0 3px 3px 0;
                            @include rotate(45deg);
                        }
                    }
                    &-forget-password {
                        text-decoration: underline;
                        line-height: 24px;
                        color: $black;
                    }
                }
                &-button {
                    width: 100%;     
                    margin-top: 30px;
                    padding: 12px 0;
                    font-size: 14px;
                    line-height: 24px;
                    background: $softBlue;
                    color: $white;
                    border-radius: 2px;
                    @include transition(0.6s, linear);
                    &:disabled, [disabled] {
                        background: $grey500;
                        color: $white;
                        cursor: auto;
                    }                   
                }
            }
            &-registration-button {
                display: none;
                text-decoration: none;
                text-align: center;
                width: 100%;     
                margin-top: 16px;
                padding: 12px 0;
                font-size: 14px;
                line-height: 24px;
                background: $white;
                color: $grey900;   
                border: 1px solid $grey900;
                border-radius: 2px;          
            }
            &-languages {
                display: none;
                justify-content: center;
                margin-top: 30px;
                font-size: 16px;
                line-height: 24px;
                color: $black;
                &-button:not(:last-child):after {
                    content: "|";
                    margin: 0 5px;
                }
            }
        }
    }
}
.footer {
    padding: 20px 100px;
    background-color: #fff;
    margin-top: 0px;
    // position: relative;
    // -webkit-animation: pullTop 1s ease-out;
    //     -moz-animation: pullTop 1s ease-out;
    //         animation: pullTop 1s ease-out;
    // padding-top: 32px;
    // padding-bottom: 32px;
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-end;
    // &-left-side {
    //     &-partner-section {
    //         display: flex;
    //         align-items: center;
    //         justify-content: flex-start;
    //         margin-bottom: 16px;
    //         &-item {
    //             display: block;
    //             margin-right: 21px;
    //             &__egov {
    //                 height: 34px;
    //             }
    //             &__idif {
    //                 height: 37px;
    //             }
    //             &__infobank {
    //                 height: 30px;
    //             }
    //             &__fimsa {
    //                 height: 13px;
    //             }
    //         }
    //     }
    //     &-copyright-section {
    //         font-size: 14px;
    //         line-height: 24px;
    //         color: $white;
    //         &-content {
    //             &:not(.footer-left-side-copyright-section-content__version):after {
    //                 content: " | ";
    //             }
    //         }
    //     }
    //     &-social-media-section {
    //         display: flex;
    //         justify-content: flex-start;
    //         list-style: none;
    //         padding-inline-start: 0;
    //         margin: 0;
    //         &-item {
    //             margin-right: 16px;
    //             &-link {
    //                 text-decoration: none;
    //                 font-size: 16px;
    //                 line-height: 24px;
    //                 color: rgba(255, 255, 255, 0.5);
    //                 &:hover {
    //                     color: $white;
                        
    //                 }
    //             }
    //         }
    //     }
    // }
    // &-right-side {
    //     // padding-right: 92px;
    //     &-propaganda {
    //         height: 30px;
    //     }
    // }
}
.ibank-home-nav{
    box-sizing: border-box;
    padding: 0px $containerHorizontalPadding!important;
    .nav-right-side{
        &-language,
        &-phone-text{
        color: $black;
       }
    }
    .header-logo{
        height: 120px;
    }
}
input[name="PINCode"]{
    text-transform: uppercase;
}
@media screen and (max-width: 700px) {
    .page-container {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .nav {
        box-sizing: border-box;
        padding: 0px 24px 0px !important;
        &-left-side {
            height: 50%;
        }
        &-right-side {
            &-language {
                display: none;
            }
            &-phone {
                margin-right: 24px;
            }
            &-register {
                display: none;
            }
            &-navbar {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 18px;
                height: 16px;
                &-line {
                    width: 18px;
                    height: 3px;
                    background: $white;
                    &__top {

                    }
                    &__middle {

                    }
                    &__bottom {

                    }
                }
                &--collapsed {
                    .nav-right-side-navbar-line {
                        width: 17px;
                        &__top {
                            @include rotate(45deg);
                            @include transformOrigin(0, 3px);
                        }
                        &__middle {
                            display: none;
                        }
                        &__bottom {
                            @include rotate(-45deg);
                            @include transformOrigin(0, 0);
                        }
                    }
                }
            }
        }
        &--transparent {
            background: transparent;
        }
        &--soft-blue {
            background: $softBlue;
        }
    }
    .header {
        &-main-section {
            display: none;
            &-slogan {
                font-size: 36px;
                line-height: 40px;
            }
            &-description {
                font-size: 14px;
                line-height: 24px;
            }
        }   
        &-sign-section {
            width: 100%;
            top: 0;
            left: 0;
            margin-top: 0;
            box-sizing: border-box;
            padding-top: 37px;
            background: $white;
            z-index: 10;
            -webkit-animation: pullLeft 0.3s ease-out;
               -moz-animation: pullLeft 0.3s ease-out;
                    animation: pullLeft 0.3s ease-out;
            &-customer{
                padding-top: 0px;
            }
            &-container{
                padding: 26px 15px 70px;
            }
        }
        &--show {
            display: block;
            .header-main-section {
                display: none;
            }
            .header-sign-section {
                width: 100%;
                display: block;
                &-customer {
                    background: $white;
                    text-align: center;
                    &-type {
                        color: $grey900;
                    }
                }
                &-container {
                    padding-top: 0;
                    &-registration-button {
                        display: block;
                    }
                    &-languages {
                        display: flex;
                    }
                }
            }
        }
    }
    .footer {
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 42px;
        &-left-side {
            &-partner-section {
                display: none;
            }
            &-copyright-section {
                margin: 0;
                &-content {
                    display: block;
                    &:after {
                        display: none;
                    }
                    &__version {
                        display: none;
                    }
                }
            }
            &-social-media-section { 
                display: none;
            }
        }
        &-right-side {
            // padding-right: 70px;
            padding-left: 24px;
            &-propaganda {
                height: 24px;
            }
        }
        .dashboard-footer-copyright{
            font-size: 12px;
        }
    }
    .ibank-sign-up{
        padding-top: 0px;
    }
}
@media screen and (min-width: 701px) {
    .nav-right-side-language {
        display: flex;
    }
}
@media screen and (min-width: 701px) and (max-width: 900px) {
    .page-container {
        padding-left: 32px;
        padding-right: 32px;
    }
    .header-main-section {
        display: none;
        // padding-right: 0;
        // width: calc(40% - 16px);
        // &-slogan {
        //     font-size: 32px;
        //     line-height: 36px;
        // }
    }
    .header-sign-section {
        width: 100%;
        // width: calc(60% - 16px);
    }
}

@media screen and (min-width: 901px) and (max-width: 1000px){
    .page-container {
        padding-left: 32px;
        padding-right: 32px;
    }
    .header-main-section {
        padding: 30px;
        width: calc(50% - 16px);
        &-slogan {
            font-size: 40px;
            line-height: 52px;
        }
    }
    .header-sign-section {
        width: calc(50% - 16px);
    }
    // .messanger-button {
    //     right: 32px;
    // }
}
@media screen and (min-width: 1001px) and (max-width: 1200px) {
    .page-container {
        padding-left: 52px;
        padding-right: 52px;
    }
    .header-main-section {
        padding: 35px;
        width: calc(50% - 16px);
        &-slogan {
            font-size: 48px;
            line-height: 48px;
        }
        &-description {
            font-size: 18px;
            line-height: 24px;
        }
    }
    .header-sign-section {
        width: calc(50% - 16px);
    }
    // .messanger-button {
    //     right: 52px;
    // }
}
@media screen and (min-width: 1201px) and (max-width: 1400px)  {
    .page-container {
        padding-left: 56px;
        padding-right: 56px;
    }
    .header-main-section {
        padding-right: 0;
        &-slogan {
            font-size: 52px;
            line-height: 52px;
        }
        &-description {
            font-size: 18px;
            line-height: 24px;
        }
    }
    // .messanger-button {
    //     right: 56px;
    // }
}
.registration-container .header-sign-section{
    width: 100%;
    text-align: center;
}
.registration-container .ibank-otp__body input{
    width: 1em!important;
}