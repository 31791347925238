.dashboard {
    &-file {
        &-list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-inline-start: 0;
            padding-top: 48px;
            list-style-type: none;
        }
        &-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            &-left {
                width: calc(100% - 14px) !important;
                width: 100%;
                display: flex;
                align-items: center;
            }
            &-right {
                width: 14px;
                &:disabled
                {
                    cursor: default;
                    .dashboard-transfer-list-table-icon path {
                        fill: rgb(88, 98, 104) !important;
                    }
                } 
            }
            &-title {
                width: calc(100% - 32px);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden; 
                margin-left: 8px;
                font-size: 14px;
                line-height: 20px;
                text-align: left !important;
                &:hover {
                    color: #0179D2;
                }
            }
            svg {
                flex-shrink: 0;
            }
        }
    }
    &-new-transfer-template-section {
        width: calc(50% - 16px);
        background: #FFFFFF;
        &-background {
            background:#E3EAED;
        }
    }
    &-new-transfer-section {
        background: #FFFFFF;
        margin-bottom: 28px;
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;
            &-title {
                margin-bottom: 0;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $black;
                &-light {
                    margin-right: 10px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    // color: #ACB3B8;
                }
                &-icon {
                    position: relative;
                    top: -2px;
                    margin-left: 6px;
                }
                &-flex {
                    display: flex;
                    align-items: center;
                }
            }
            &-history {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $black;
                text-decoration: none;
                @include transition(0.5s, linear);
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &-content {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            padding: 28px 24px;
            // :nth-child(5) {
            //     margin-top: 24px;
            // }
            // :nth-child(6) {
            //     margin-top: 24px;
            // }
            // :nth-child(7) {
            //     margin-top: 24px;
            // }
            // :nth-child(8) {
            //     margin-top: 24px;
            // }
            &-item {
                text-decoration: none;
                padding: 19px 0;
                width: calc((100% - 72px) / 4);
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #F3F3F3;
                box-sizing: border-box;
                border-radius: 4px;
                margin-bottom: 24px;
                &:not(:nth-child(4n)) {
                    margin-right: 24px;
                }
                &__large {
                    width: calc((100% - 48px) / 3);
                    &:not(:nth-child(4n)) {
                        margin-right: 0;
                    }
                    &:not(:nth-child(3n)) {
                        margin-right: 24px;
                    }
                }
            
                &-icon-section {

                }
                &-icon {

                }
                &-title {
                    margin: 0;
                    margin-top: 12px;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    color: #2E3131;
                }
            }
        }
    }
    &-transfer-history {
        width: calc(50% - 16px);
        background: #FFFFFF;
        &-header {
            display: flex;
            align-items: center;
            min-height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid $greyShadow;
            overflow-x: auto;
            &-title {
                text-decoration: none;
                font-weight: 400;
                font-size: 12px;
                line-height: 24px;
                padding: 16px 0;
                color: #959FA5;
                margin-right: 16px;
                text-align: center;
                border-bottom: 2px solid transparent;
                &__bold {
                    // color: #2E3131;
                    padding-top: 18px;
                    padding-bottom: 18px;
                }
                &__active {
                    color: #2E3131;
                    border-bottom: 2px solid #0179D2;
                }
            }
        }
        &-content {
            margin: 0;
            padding-inline-start: 0;
            padding: 16px 24px;
            &-item {
                padding: 12px 0;
                border-bottom: 1px solid #E8ECEF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                list-style-type: none;
                &__clickable {
                    padding-left: 24px;
                    padding-right: 24px;
                    cursor: pointer;
                    &:hover {
                        background: rgba(1, 121, 210, 0.05);
                    }
                }
                &-container {
                    &__left-side {
                        display: flex;
                        align-items: center;
                    }
                    &__right-side {
                        display: flex;
                        align-items: center;
                        text-align: right;
                    }
                    &-icon {
                        height: 12PX;
                    }
                    &-icon-section {
                        flex-shrink: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 40px;
                        background: #F0F7FC;
                        @include border-radius(50%);
                        margin-right: 12px;
                    }
                    &-name {
                        margin: 0;
                        font-size: 400px;
                        font-size: 16px;
                        line-height: 24px;
                        color: #586268;
                        text-align: left;
                    }
                    &-iban {
                        margin: 0;
                        font-size: 400px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                        color: #959FA5;
                    }
                    &-amount {
                        font-size: 16px;
                        line-height: 24px;
                        color: #2E3131;
                        &-whole {
                            font-weight: 400;
                        }
                        &-fractional {
                            font-size: 14px;
                            font-weight: 300;
                            &::before {
                                content: ".";
                            }
                        }
                        &-currency {
                            margin-left: 2px;
                            font-weight: 300;   
                            font-size: 14px;
                        }
                    }
                    &-type {
                        margin: 0;
                        font-size: 12px;
                        line-height: 16px;
                        color: #959FA5;
                    }
                    &-type-circle {
                        flex-shrink: 0;
                        margin-left: 16px;
                        width: 12px;
                        height: 12px;
                        @include border-radius(50%);
                        &__confirmed {
                            background: #6DD230;
                        }
                        &__waiting {
                            background: #FFAB2B;
                        }
                        &__first-authorization {
                            background: #0179D2;
                        }
                        &__second-authorization {
                            background: #4D7CFE;
                        }
                        &__refused {
                            background: #EB001B;
                        }
                    }
                    &-bonus {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        &-green-text {
                            color: #1CB848;
                        }
                        &-icon {
                            margin-left: 4px;
                            position: relative;
                            top: -2px;
                        }
                    }
                }
          
            }
        }
    }
    &-transfer-list {
        background: #FFFFFF;
        width: 100%;
        @include border-radius(4px);
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #E8ECEF;
            &-container {
               
                &__left-side {

                }
                &__right-side {

                }
            }
            &-link {
                display: inline-block;
                padding-top: 14px;
                padding-bottom: 16px;
                font-size: 16px;
                line-height: 24px;
                text-decoration: none;
                color: #959FA5;
                border-bottom: 2px solid transparent;
                &:not(:last-child) {
                    margin-right: 16px;
                }
                &__active {
                    color: #2E3131;
                    border-bottom: 2px solid #0179D2;
                }            
            }
            &-button {
                padding: 8px 16px;
                font-size: 14px;
                line-height: 16px;
                background: #0179D2;
                color: #FFFFFF;
                @include border-radius(2px);
                &:disabled {
                    background: #BDC3C7;
                }
            }
        }
        &-content {
            min-height: calc(100vh - 268px);
            padding: 16px 24px !important;
            overflow-x: auto;
        }
        &-table {
            display: table;
            flex-direction: column;
            width: 100%;
            border-collapse: collapse;
            -webkit-border-horizontal-spacing: 0 !important;
            -webkit-border-vertical-spacing: 0 !important;
            &-row {
                height: 56px;
                border-bottom: 1px solid #E4E8EE;
                &:hover {
                    background: rgba(1, 121, 210, 0.05);
                }
                &__head {
                    background: #FFFFFF !important;
                }
            }
            &-cell {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: #2E3131;
                text-align: left;
                &__head {
                    color: #586268;
                }
                &:first-child {
                    padding-left: 16px;
                }
                &:last-child {
                    padding-right: 16px;
                }
                padding-right: 10px;
                &__blue {
                    font-size: 14px;
                    line-height: 24px;
                    color: #0179D2;
                }
                &-iban{
                    margin: 0px
                }
                &-card-number{
                    margin-bottom: 10px;
                }
            }
            &-icon {
                path {
                    @include transition(0.6s, ease);
                }
                &:hover {
                    path {
                        fill: #0179D2;
                    }
                }
            }
        }
    }
    &-transfer-search {
        &-form-section {
            display: flex;
            flex-wrap: wrap;
            background: #FFFFFF;
            border-radius: 4px;
            padding: 24px;
            box-sizing: border-box;
            &-redesign{
                row-gap: 1rem;
                justify-content: space-between;
                margin-top: 24px;
                padding-inline: 24px;
            }
            &-item {
                width: calc((100% - 2 * 24px) / 3);
                margin-bottom: 24px;
                &:not(:nth-child(3n)) {
                    margin-right: 24px;
                }
                &-button {
                    width: 100%;
                    background: #0179D2;
                    border-radius: 2px;
                    height: 48px;
                    line-height: 48px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    color: #FFFFFF;
                    &__disable {
                        background: rgba(239, 239, 239, 0.3);
                        color: #ACB3B8 !important;
                        cursor: auto;
                    }
                }
            }
        }
        &-list-section {
            margin-top: 24px;
            background: #FFFFFF;
            border-radius: 4px;
            min-height: 400px;
        }
    }
}

$breakpoint-tablet: 768px;
$breakpoint-mobile: 530px;
@media (max-width: $breakpoint-tablet) {
    .dashboard {
        &-new-transfer-template-section {
            width: 100%;
            &__bottom {
                margin-bottom: 28px;
            }
        }
        &-new-transfer-section {
            width: 100%;
            &-content {
                &-item {
                    width: calc((100% - 24px) / 2);
                //     margin-bottom: 24px;
                    &:not(:nth-child(4n)) {
                        margin-right: 0;
                    }
                    &:not(:nth-child(3n)) {
                        margin-right: 0;
                    }
                    &:nth-child(odd) {
                        margin-right: 24px;
                    }
                //     &__large {
                //         width: calc((100% - 24px) / 2);
                //         &:not(:nth-child(3n)) {
                //             margin-right: 0;
                //         }
                //         &:not(:nth-child(3n)) {
                //             margin-right: 24px;
                //         }
                //     }
                }
            }
        }
        &-transfer-history {
            width: 100%;
            &-header {
                flex-wrap: wrap;
                &-title {
                    font-size: 10px;
                }
            }
            &-content {
                overflow: auto;
                &-item {
                    min-width: 520px;
                }
            }
        }
    }
    .dashboard-transfer-search-form-section {
        flex-direction: column;
        &-item {
            width: 100%;
            margin-right: 0;
        }
    }
    .dashboard-transfer-list {
        &-content {
            overflow-x: auto;
        }
        &-table {
            min-width: 1200px;
        }
    }
    .responsive-width-full {
        width: 100%!important;
        &-top {
            width: 100%!important;
            margin-top: 28px;
        }
    }
    
    
}
.red-circle {
    position: relative;
    top: -12px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #F94342;
    border-radius: 50%;
}
.friend-transfer-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
}
.friend-transfer-button {
    width: 56px;
    height: 56px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
    &__accept {
        background-color: #61BA66;
        margin-right: 32px;
    }
    &__reject {
        background-color: #EF3E42;
    }
}
.express-operation-code {
    padding: 4px 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF !important;
    background: #61BA66;
    border-radius: 20px;
}
.tooltip div.tooltip-inner{
    background-color: #2C81C1;
    color: white;
}