@import './../components/variables';

legend {
    display: none !important;
}

.disabled {
    opacity: .5;
}

.dashboard {
    &-salary-container {
        display: flex;
        margin: 28px 32px;
        @include border-radius(4px);
        min-height: calc(100vh - 140px);

        &-data-section {
            width: 100%;
            background: #FFFFFF;
        }

        p {
            margin-bottom: 0;
        }
    }

    &-salary-order {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 24px;
            overflow: auto;

            table {
                thead {
                    tr {
                        border: 1px solid rgba(0, 102, 178, 0.1);
                        background-color: #E6F2FB;

                        th {
                            font-weight: 400;
                            padding: 12px;
                        }
                    }
                }

                tbody {
                    tr {
                        border: 1px solid rgba(0, 102, 178, 0.1);

                        td {
                            padding: 20px 12px;

                            button {
                                border-radius: 6px;
                                padding: 4px 20px;

                                &:hover {
                                    background-color: #1976d20a;
                                }

                                &.button-close {
                                    background-color: rgba(217, 45, 32, 0.1);
                                    color: #D92D20;
                                }

                                &.button-open {
                                    background-color: rgba(102, 198, 28, 0.1);
                                    color: #66C61C;
                                }
                            }
                        }
                    }
                }
            }

            &-box {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                position: relative;
                // overflow: hidden;
                row-gap: 1.7rem;
                column-gap: .9rem;

                &-select {
                    display: flex;
                    flex-direction: column;
                    // gap: .6rem;
                    width: 23%;

                    @media screen and (max-width: 756px) {
                        width: 100%;
                    }

                    &-filter{
                        // overflow-x: hidden;
                        // overflow-y: auto;
                        width: 350px;
                    }

                    &-input {
                        font-size: 12px;
                        border: 0;
                        box-sizing: border-box;
                        padding: 12px 0 12px 16px;
                        height: 48px;
                        border-radius: 2px;
                        border: 1px solid #D0D5DD;
                        font-size: 1rem;

                        &:hover:not(.input-outline) {
                            border: 1px solid #000000;
                        }

                        &:active,
                        &:focus {
                            border: 2px solid #1976d2;
                        }
                    }

                    .MuiFormControl-root {
                        height: 48px;

                        &>div {
                            height: 100%;

                            .MuiIconButton-root {
                                border-left: none !important;
                            }
                        }
                    }

                    label {
                        font-weight: 500;
                        text-wrap: nowrap;
                    }

                    &-group {
                        position: relative;
                        border: 1px solid #D0D5DD;
                        background-color: $white;
                        height: 48px;
                        box-sizing: border-box;

                        &.input-error {
                            border: 1px solid #EF3E42;
                        }

                        &:hover:not(.input-error) {
                            border-color: #000000;
                        }

                        &-input {
                            font-size: 12px;
                            width: calc(100% - 50px);
                            border: 0;
                            padding: 16px 14px;
                            box-sizing: border-box;
                            height: 100%;
                            pointer-events: none;

                            &-icon {
                                position: absolute;
                                top: 0;
                                right: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 100%;
                                width: 50px;
                                cursor: pointer;
                                // background-color: #1976d20a;
                            }

                            &-select {
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 50px;
                                height: 100%;
                                opacity: 0;
                                cursor: pointer;
                                z-index: 100;
                                font-size: 0px;
                            }
                        }
                    }

                    &-upload {
                        background-color: $softBlue;
                        border-radius: 2px;
                        color: white;
                        height: 48px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 12px;
                    }

                    &-datepicker {
                        display: flex;
                        gap: .3rem;

                        &-input {
                            border: 1px solid #D0D5DD;
                            font-size: 1rem;

                            &:hover {
                                border: 1px solid #000000;
                            }

                            &:active,
                            &:focus {
                                border: 1px solid #1976d2;
                            }
                        }

                    }
                }

                &-upload {
                    button {}
                }

                &-header {
                    display: flex;
                    justify-content: space-between;

                    button {
                        background-color: $white;
                        border-radius: 4px;
                        // padding: 4px;
                        width: 24px;
                        height: 24px;

                        &:hover {
                            background-color: #1976d20a;
                        }
                    }
                }

                &-customer {
                    background-color: $grey100;
                    display: flex;
                    flex-direction: column;
                    row-gap: .5rem;
                    // flex-wrap: wrap;
                    padding: 24px;

                    // label {
                    //     width: 100%;
                    // }
                }

                &-button {
                    padding: 12px 24px;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    border-radius: 2px;
                    width: 134px;
                    box-sizing: border-box;

                    &-submit-order {
                        background-color: $softBlue;
                        color: white;
                        padding: 12px 44px;
                        font-weight: 400;
                        transition: .2s;

                        &:hover {
                            background-color: #0063af;
                        }
                    }

                    &-delete-order {
                        background-color: #FEF3F2;
                        color: #D92D20;
                        padding: 12px 44px;
                        font-weight: 500;
                        transition: .2s;

                        &:hover {
                            background-color: #ffe6e3;
                        }

                    }

                    &-delete {
                        position: absolute;
                        right: 0;
                        top: -10px;
                    }

                    &-main {
                        background-color: $softBlue;
                        color: white;
                        text-wrap: nowrap;
                        &:hover {
                            background-color: #0063af;
                        }
                    }

                    &-outline {
                        background-color: $white;
                        color: $softBlue;
                        border: 1px solid $softBlue;
                        text-wrap: nowrap;

                        &:hover {
                            background-color: #1976d213;
                        }
                    }
                }
            }

            .MuiAutocomplete-root {
                width: 100%;

                .MuiOutlinedInput-root {
                    padding: 5px;
                }

                * {
                    color: #667085;
                    // background-color: $white;
                }
            }

            .MuiFormControl-root {
                background-color: $white;
            }

            .MuiFormHelperText-root {
                margin-inline: 0;

                &.Mui-required::before {
                    content: url("../../../Dashboard/img/info-circle.svg");
                    position: relative;
                    top: 2px;
                    margin-right: 3px;
                }
            }

            fieldset {
                border-color: #D0D5DD;
                border-radius: 2px;
                height: 100%;
                top: 0;
            }

            &-buttons {
                display: flex;
                gap: .5rem;
                width: fit-content;
                padding: 4px;
                border: 1px solid #E4E7EC;
                border-radius: 10px;
                background-color: #F9FAFB;
                @media screen and (max-width: 756px) {
                    flex-direction: column;
                    width: 100%;
                }
            }

            &-button {
                padding: 9px 14px;
                display: flex;
                height: 38px;
                border-radius: 6px;
                gap: .2rem;
                align-items: center;
                color: #667085;
                font-weight: 500;
                transition: .2s;

                &:hover:not(&__active) {
                    background-color: #1976d20a;
                }

                &__active {
                    background-color: $softBlue;
                    color: $white;

                    svg {
                        filter: brightness(2.5);
                    }
                }
            }
        }
    }
}